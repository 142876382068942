<template>
  <v-card hover class="slide-card" rounded="lg">
    <v-img
      contain
      loading="lazy"
      :src="landscape || '/assets/img/slides/placeholder-slide.svg'"
      alt
      :aspect-ratio="16 / 9"
      @click="handleCardClick('thumbnail')"
    >
      <div class="image-overlay-content">
        <div class="badge-container">
          <v-badge
            v-if="showBadge"
            :content="$t('common.newContent')"
            overlap
            tile
            offset-x="0"
            offset-y="0"
            :style="{ zIndex: 4 }"
          ></v-badge>
        </div>
        <v-tooltip
          bottom
          max-width="200"
          content-class="tooltip-content"
          fixed
          v-if="slideMetaData.isTrending"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              src="/assets/img/trending.svg"
              alt
              width="34px"
              height="34px"
              class="bpe-trending--auto"
            />
          </template>
          <span> {{ $t('bestPracticesGuide.knowSlideCard.trending') }} </span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="200"
          content-class="tooltip-content"
          fixed
          v-if="slideMetaData.isImageReplaced || slideMetaData.isIconReplaced"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              src="/assets/img/already-downloaded-image-replaced.svg"
              alt
              width="34px"
              height="34px"
              class="bpe-replaced-download--auto bpe-download--auto"
            />
          </template>
          <span> {{ $t('bestPracticesGuide.knowSlideCard.downloaded') }} </span>
        </v-tooltip>
        <v-tooltip
          bottom
          max-width="200"
          content-class="tooltip-content"
          fixed
          v-else-if="slideMetaData.isDownloaded"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              src="/assets/img/already-downloaded.svg"
              alt
              width="34px"
              height="34px"
              class="bpe-download--auto"
            />
          </template>
          <span> {{ $t('bestPracticesGuide.knowSlideCard.downloaded') }} </span>
        </v-tooltip>
      </div>
    </v-img>

    <v-card-actions>
      <div class="action-items">
        <v-row>
          <v-col class="slide-title">
            <span>{{ slide.title }}</span>
          </v-col>
          <v-col class="d-flex col-auto">
            <v-row align="center" justify="end">
              <v-tooltip bottom max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="toggleFav === true ? null : handleFavourite()"
                  >
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="toggleFav === true"
                    >
                      {{
                        slideMetaData.isFavourite
                          ? 'mdi-heart'
                          : 'mdi-heart-outline'
                      }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    slideMetaData.isFavourite
                      ? $t('bestPracticesGuide.knowSlideCard.removeFavorites')
                      : $t('bestPracticesGuide.knowSlideCard.addFavorites')
                  }}
                </span>
              </v-tooltip>
              <v-progress-circular
                indeterminate
                :size="20"
                color="primary"
                v-if="isLocalDownloading"
                :style="{ margin: '0px 8px 0px 8px' }"
              />
              <Download
                v-else
                showIconButton
                :mode="'icon'"
                :file-name="slide.filename || ''"
                :item="slide"
                :log-download="logDownload"
                :count-first-time="1"
                :on-download="makeLocalDownload"
                origin="bpe-slide"
                limitsKey="bpe-slide"
              />
              <v-tooltip
                bottom
                max-width="200"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="copyBPESlideLink()"
                  >
                    mdi-link-variant
                  </v-icon>
                </template>
                <span>
                  {{
                    `${
                      showCopied
                        ? $t('bestPracticesGuide.knowSlideCard.linkCopied')
                        : $t('navbarActions.shareFreeTrial.copyLink')
                    }`
                  }}
                </span>
              </v-tooltip>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="sub-action-item">
          <v-col>
            <!-- <span class="label"> Author: </span> -->
            <div class="author-label">
              <!-- <template v-if="slide.prefs.author">
                <span>Author: </span>
                <span>{{ slide.prefs.author }}</span>
                <span> &#183; </span>
                <span>Created on: {{ getFormatedDate }}</span>
              </template> -->
            </div>
            <div class="typeAndDate">
              <v-tooltip
                bottom
                max-width="400"
                content-class="tooltip-content"
                fixed
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="descripion" v-bind="attrs" v-on="on">
                    {{ $t('userUploadPrezentation.description') }}:
                    {{
                      getDescription(slide.title) ||
                      getDescription(slide.asset.title)
                    }}
                  </div>
                </template>
                <span>
                  {{
                    getDescription(slide.title) ||
                    getDescription(slide.asset.title)
                  }}
                </span>
              </v-tooltip>
            </div>
            <div class="viewAndtype">
              <div class="views">
                {{
                  `${
                    slideMetaData.viewCount > 0
                      ? `${slideMetaData.viewCount} ${
                          slideMetaData.viewCount === 1
                            ? $t('bestPracticesGuide.knowSlideCard.view')
                            : $t('bestPracticesGuide.knowSlideCard.views')
                        }`
                      : $t('bestPracticesGuide.knowSlideCard.noViews')
                  }`
                }}
              </div>
              <template
                v-if="slide.related_words && slide.related_words.length > 0"
              >
                <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
                <div class="also-relevant-to">
                  <v-tooltip
                    bottom
                    max-width="400"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div class="descripion" v-bind="attrs" v-on="on">
                        {{
                          $t('bestPracticesGuide.knowContainer.alsoReleventTo')
                        }}
                        {{ slide.related_words }}
                      </div>
                    </template>
                    <span>
                      {{ slide.related_words }}
                    </span>
                  </v-tooltip>
                </div></template
              >
            </div>
            <div class="author-label">
              <span>
                {{ $t('bestPracticesGuide.knowSlideCard.source') }}:
                {{
                  slide.prefs.source === 'prezent'
                    ? $t('common.prezent')
                    : sourceDisplayNameRef &&
                      sourceDisplayNameRef[slide.prefs.source]
                    ? sourceDisplayNameRef[slide.prefs.source]
                    : slide.prefs.source
                }}
              </span>
            </div>
          </v-col>
          <!-- <v-col align="end"> logo </v-col> -->
        </v-row>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { getFileURL } from '@/utils/calyrex';
import Download from '@/components/common/Download';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import {
  createKnowDownload,
  createKnowFavorite,
  deleteKnowFavorite,
} from '../../../utils/api-helper';
// import { downloadFileWithCustomName } from '../../../utils/common';
import usersApi from '../../../API/users-api';
import {
  TD_ISDOWNLOAD,
  TD_ISFAVORITED,
  TD_SLIDEID,
  getSlideId,
  NA,
  TD_DOWNLOADTYPE,
  TD_DOWNLOADTYPE_GS,
  TD_DOWNLOADTYPE_DIRECT,
  TD_HYBRID_SEARCH,
  TD_HYBRID_RESULTS_SECTION_CLICK,
  TD_HYBRIDCHIP,
  TD_QUERY,
  TD_HYBRID_RESULTS_SECTION_KNOW,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackKnowEvents } from '../../common/Analytics/KnowEvents';
import { trackSearchEvents } from '../../common/Analytics/SearchEvents';
import { getTooltipForKeyword } from '../../common/KeywordTooltipMapping';

export default {
  name: 'Slide',
  components: {
    Download,
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
    metaData: {
      type: Object,
      required: false,
    },
    origin: {
      type: String,
      required: false,
    },
    companyTheme: {
      type: String,
      required: false,
    },
    handleDetailClick: {
      type: Function,
      default: () => {},
    },
  },
  data: (props) => ({
    landscape: '',
    slideMetaData: { ...props.metaData },
    toggleFav: false,
    showCopied: false,
    downloadingSlide: false,
  }),
  watch: {
    metaData(data) {
      this.slideMetaData = { ...data };
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentTheme',
      'hybridSearchQueryPayload',
      'themes',
    ]),
    ...mapState('knowStore', ['slidesMeta', 'sourceDisplayNameRef']),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),

    isLocalDownloading() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.slide.unique_id,
      );
      return ldr && ldr.downloading;
    },

    getFormatedDate() {
      if (this.slide.prefs.created_at)
        return moment(this.slide.prefs.created_at, 'YYYYMMDDhhmmss').format(
          'MM/DD/YYYY',
        );

      return '';
    },
    showBadge() {
      if (!this.currentUser.user.createdAt || !this.slide.prefs.created_at) {
        return false;
      }
      const userCreatedAt = moment(this.currentUser.user.createdAt);
      const prezDataCreatedAt = moment(this.slide.prefs.created_at);
      const currentDate = moment();

      return (
        currentDate.diff(prezDataCreatedAt, 'days') < 28 &&
        userCreatedAt.isBefore(prezDataCreatedAt)
      );
    },
  },
  async beforeMount() {
    this.landscape = await getFileURL(
      this.currentUser.user.cognitoID,
      this.slide.landscape,
      this.currentUser.userIp,
    );
  },
  methods: {
    ...mapActions('knowStore', [
      'setSlideDetail',
      'setSlideMetaData',
      'setSlidesMeta',
    ]),
    ...mapActions('search', ['updateRecentQueries']),
    ...mapActions('users', ['setCurrentUser']),

    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      'setNavFromAddToLibOrFavSnackbar',
      'setAddedToLibOrFavSnackbar',
    ]),

    getDescription(keyword) {
      return getTooltipForKeyword(keyword);
    },
    copyBPESlideLink() {
      navigator.clipboard.writeText(
        `${window.location.origin}/home/best-practice-library/${this.slide?.unique_id}`,
      );
      this.showCopied = true;
      setTimeout(() => {
        this.showCopied = false;
      }, 1000);
    },
    handleCardClick(origin) {
      // this.setSlideDetail(this.slide);
      this.setSlideMetaData(this.slideMetaData);
      if (this.origin && this.origin === TD_HYBRID_SEARCH) {
        this.handleDetailClick();
        const payload = {
          type: 'Best Practice Slides',
          value: this.slide.title,
          subvalue: this.slide.title,
          attributes: `/home/best-practice-guides?category=${this.slide.category}&query=${this.slide.display_name}`,
          object: this.slide,
        };
        this.updateRecentQueries(payload);
        const { selectedChip, query } = this.hybridSearchQueryPayload;
        trackSearchEvents.hybridSearchSlideClick(this.slide, this.currentUser, {
          [TD_HYBRID_RESULTS_SECTION_CLICK]: TD_HYBRID_RESULTS_SECTION_KNOW,
          [TD_HYBRIDCHIP]: selectedChip,
          [TD_QUERY]: query,
        });
      } else {
        trackKnowEvents.knowThumbnailClick(
          { ...this.slide, origin },
          this.currentUser,
        );
      }
      this.$router.push(
        `/home/best-practice-library/${this.slide.unique_id}?origin=${origin}`,
        () => {},
      );
    },
    async refreshCurrentUserInfo() {
      const userinfo = await usersApi.methods.getUserInfo();
      return userinfo;
    },
    getCommonOtherDataForSlideDownload(item) {
      return {
        [TD_ISDOWNLOAD]: 1,
        [TD_ISFAVORITED]: this.slideMetaData?.isFavourite ? '1' : NA,
        [TD_SLIDEID]: getSlideId(item),
      };
    },
    async logDownload(isNotDirectDownload) {
      if (isNotDirectDownload) {
        trackKnowEvents.know(this.slide, this.currentUser, {
          ...this.getCommonOtherDataForSlideDownload(this.slide),
          [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_GS,
        });
      }

      await createKnowDownload({
        assetID: this.slide.unique_id,
        idea: this.slide.title,
        construct: this.slide.constructs[0],
        themeID: this.slide.prefs.theme, // this.currentTheme.code,
        prefs: this.slide.prefs,
      })
        .then((res) => {
          console.log(res);
          if (!this.slideMetaData.isDownloaded) {
            this.slideMetaData = {
              ...this.slideMetaData,
              isDownloaded: !this.slideMetaData.isDownloaded,
            };
          }
          // const data = { ...this.slidesMeta };
          // data[this.slide.unique_id].isDownloaded = true;
          this.setSlidesMeta(this.slideMetaData);
        })
        .catch((error) => console.log(error));
      const currUser = await this.refreshCurrentUserInfo();
      this.setCurrentUser(currUser);
    },

    capitalizeFirstLetter(str) {
      if (!str) {
        return '';
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    getSlideBasedTemplateForDownload(code) {
      const template = this.themes.find((thm) => thm.code === code);
      if (template) {
        return template.name;
      }
      return code;
    },

    async makeLocalDownload() {
      AnalyticsHandler.slideDownloadStarted(this.currentUser, this.slide.asset);
      AnalyticsHandler.logUserStats(this.currentUser);

      const filePath = this.slide.filename
        ? this.slide.filename
        : this.slide.asset.filename;

      let fileName = '';
      if (this.slide.asset) {
        fileName = `${this.capitalizeFirstLetter(
          this.slide.asset.title || this.slide.asset.categories[0],
        )} - ${this.getSlideBasedTemplateForDownload(
          this.slide.asset.prefs.theme,
        )}`;
      } else {
        fileName = `${this.capitalizeFirstLetter(
          this.slide.title || this.slide.categories[0],
        )} - ${this.getSlideBasedTemplateForDownload(this.slide.prefs.theme)}`;
      }

      this.setShowDownloadSnackbar(true);
      this.setDownloadLoaders({
        loaderId: this.slide.unique_id,
        downloading: true,
      });
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'bpeSlideCard',
        logDownloadCallback: async () => {
          await this.logDownload(false);
          AnalyticsHandler.slideDownloadComplete(
            this.currentUser,
            this.slide.asset,
          );
          trackKnowEvents.know(this.slide, this.currentUser, {
            ...this.getCommonOtherDataForSlideDownload(this.slide),
            [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
          });
        },
        mergeSlidesCallback: () => {},
        downloadStatusCallback: () => {
          this.setDownloadLoaders({
            loaderId: this.slide.unique_id,
            downloading: false,
          });
        },
        metaData: {
          slideDetail: { ...this.slide },
          filePath,
          fileName,
          limitsKey: 'bpe-slide',
          origin: 'bpe-slide',
        },
      });

      // this.downloadingSlide = true;
      // try {
      //   await this.handleDownloadSlide();
      //   this.downloadingSlide = false;
      // } catch (err) {
      //   console.log(err);
      //   this.downloadingSlide = false;
      // } finally {
      //   this.downloadingSlide = false;
      // }
    },

    // async handleDownloadSlide() {
    //   AnalyticsHandler.slideDownloadStarted(this.currentUser, this.slide.asset);
    //   AnalyticsHandler.logUserStats(this.currentUser);
    //   const filePath = this.slide.filename
    //     ? this.slide.filename
    //     : this.slide.asset.filename;
    //   await this.logDownload(false);
    //   AnalyticsHandler.slideDownloadComplete(
    //     this.currentUser,
    //     this.slide.asset,
    //   );
    //   trackKnowEvents.know(this.slide, this.currentUser, {
    //     ...this.getCommonOtherDataForSlideDownload(this.slide),
    //     [TD_DOWNLOADTYPE]: TD_DOWNLOADTYPE_DIRECT,
    //   });
    //   const fileUrl = await getFileURL(
    //     this.currentUser.user.id,
    //     filePath,
    //     this.currentUser.userIp,
    //   );
    //   let fileName = '';
    //   if (this.slide.asset) {
    //     fileName = `${this.slide.asset.categories[0]}_${this.slide.asset.prefs.theme}`;
    //   } else {
    //     fileName = `${this.slide.categories[0]}_${this.slide.prefs.theme}`;
    //   }

    //   await downloadFileWithCustomName(fileUrl, fileName);
    // },

    goToFavBpLib() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: 'addToFavSlidesBp',
        origin: 'fav_slides_bp',
      });
      this.$router.push('/home/best-practice-library').catch(() => {});
    },

    async handleFavourite() {
      if (this.slideMetaData.isFavourite) {
        this.toggleFav = true;
        await deleteKnowFavorite(this.slide.unique_id).catch((err) =>
          console.log(err),
        );
        this.toggleFav = false;
      } else {
        const favoriteData = {
          assetID: this.slide.unique_id,
          themeID: this.slide.prefs.theme,
        };
        this.toggleFav = true;
        await createKnowFavorite(favoriteData)
          .then((res) => {
            console.log(res);

            this.setAddedToLibOrFavSnackbar({
              show: true,
              ctaText: 'snackBarItems.goToFavorites',
              snackbarText: 'snackBarItems.slideAddedToFav',
              navFn: this.goToFavBpLib,
              snackbarType: 'goToFav',
            });
          })
          .catch((err) => console.log(err));
        this.toggleFav = false;
        // API.graphql(graphqlOperation(createFavorite, { input: favoriteData }))
        //   .then((res) => {
        //     this.slideMetaData = {
        //       ...this.slideMetaData,
        //       favouriteId: res.data.createFavorite.id,
        //     };
        //   })
        //   .catch((err) => console.log(err));
        AnalyticsHandler.markedSlideFavorite(
          this.currentUser,
          this.slide.unique_id,
        );
        const { asset } = this.slide;
        const slideTitle = this.slide.title || asset?.title;
        const slideId = this.slide.unique_id || asset?.unique_id;

        MatomoAnalyticsHandler.slideFavorited(
          slideTitle,
          slideId,
          this.currentUser,
          {
            audience: {
              email: this.currentUser?.id || this.currentUser?.user?.id,
            },
          },
        );
      }

      this.slideMetaData = {
        ...this.slideMetaData,
        isFavourite: !this.slideMetaData.isFavourite,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.slide-card {
  margin: 8px;
  .v-card__actions {
    flex-direction: column;
    padding: 1rem;
    cursor: auto;
    border-top: 1px solid #ededed;

    .action-items {
      width: 100%;
      .slide-title {
        font-size: 16px;
      }
      .sub-action-item {
        margin-top: 0;

        .label {
          color: $gray-dark-login-text;
        }
      }
    }
  }
  ::v-deep .download-icon {
    cursor: pointer;

    :hover {
      background-color: unset !important;
    }
  }

  .author-label {
    color: $gray-dark-login-text;
    font-size: 12px;
  }
  .typeAndDate {
    color: rgb(130 131 130);
    font-size: 12px;
    display: flex;
    margin: 2px 0px;
    .descripion {
      text-align: left;
      max-width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .viewAndtype {
    color: rgb(130 131 130);
    font-size: 12px;
    display: flex;
    margin: 2px 0px;
    .views {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15%;
    }
    .also-relevant-to {
      max-width: 75%;
    }
    .descripion {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .image-overlay-content {
    display: flex;
    float: right;
    margin: 5px;
    cursor: auto;
    z-index: 10;
    .badge-container {
      display: flex;
      top: 40px;
      left: 14px;
      position: absolute;
      z-index: 4;

      ::v-deep .v-badge__badge {
        background-image: $new-tag-bg;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 600;
        height: fit-content;
        padding: 6px 16px;
        width: fit-content;
        z-index: 1;
      }
    }
  }
}
</style>
