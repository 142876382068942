<template>
  <div>
    <div
      class="brandImagesLib"
      v-if="
        getReplaceImageSuggestionState &&
        getReplaceImageSuggestionState.panelOpen
      "
    >
      <ReplaceComplyImages
        :handleAcceptFromBrandImageLib="handleAcceptFromBrandImageLib"
        :handleRejectFromBrandImageLib="handleRejectFromBrandImageLib"
        :origin="'comply'"
      />
    </div>
    <div
      class="compliance-score-box"
      v-if="
        (!getDeckFullComplianceInfo &&
          !colorPickerState.panelOpen &&
          !getReplaceImageSuggestionState.panelOpen) ||
        (reviewClicked &&
          !colorPickerState.panelOpen &&
          !getReplaceImageSuggestionState.panelOpen)
      "
    >
      <v-row class="slide-compliance-score-box">
        <v-col cols="8" class="score-text-col">
          <span class="score-text" data-pendo-id="comply-deck-compliance-score"
            >Brand Compliance Score</span
          >
          <span class="score-percentage">{{ complianceScore }}%</span>
        </v-col>
        <v-col cols="4" class="progress-col">
          <v-progress-linear
            :value="complianceScore"
            :color="complianceColor"
            height="7"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <div v-if="colorPickerState && colorPickerState.panelOpen">
      <ComplyColorPicker
        @close="closeColorPicker"
        @accept="onSuggestionAcceptFromPicker"
        @reject="onSuggestionRejectFromPicker"
        :origin="'deck'"
      ></ComplyColorPicker>
    </div>
    <div>
      <v-row
        v-if="
          (!getDeckFullComplianceInfo &&
            !colorPickerState.panelOpen &&
            !getReplaceImageSuggestionState.panelOpen) ||
          (reviewClicked &&
            !colorPickerState.panelOpen &&
            !getReplaceImageSuggestionState.panelOpen)
        "
        class="expansionPanelsSuggestions"
      >
        <v-col>
          <v-expansion-panels v-model="panel">
            <v-expansion-panel
              v-for="(category, i) in getDeckLevelCategorySuggestions"
              :key="i"
              class="suggestions__panel"
              :disabled="
                disableSuggestionsDropdown(category) ||
                (!allowReplaceImageOverall && category.key === 'images')
              "
              @click="selectCategory(category)"
            >
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <div class="suggestion-details">
                    <div>
                      <v-avatar
                        :color="category.avatarColor"
                        :size="16"
                      ></v-avatar>
                      <span class="suggestion-details__item-text">{{
                        category.text
                      }}</span>
                      <v-icon :color="'#D9D9D9'">mdi-circle-small</v-icon>
                      <span class="suggestion-details__total">
                        {{ getSuggestionsCountText(category) }}
                      </span>
                    </div>

                    <div
                      style="margin-left: auto"
                      class="suggestion-details__actions"
                      v-if="
                        category.key !== 'template' &&
                        !isSuggestionsResolved(category)
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="onCategoryAccept(category)"
                          >
                            <v-icon color="#00925a">mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>Accept</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="onCategoryReject(category)"
                          >
                            <v-icon color="#d32f2f">mdi-close</v-icon>
                          </v-btn>
                        </template>
                        <span>Reject</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div
                    class="suggestion-details__description"
                    v-if="
                      !(
                        isReplaceBrandImageEnabled && allowReplaceImageOverall
                      ) && category.key === 'images'
                    "
                  >
                    No brand images available to check compliance.
                    <a class="text-link" @click="onGetInTouchclick">
                      Get in touch
                    </a>
                    to add brand images.
                  </div>
                  <div class="suggestion-details__description" v-else>
                    {{ getSuggestionDescription(category) }}
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                :key="category.text"
                class="expansion-panel-content"
              >
                <v-list>
                  <v-list-group
                    v-for="(suggestion, index) in category.suggestions"
                    :key="suggestion.name + index"
                    no-action
                    :value="subPanel[category.key] == suggestion.index"
                  >
                    <template v-slot:activator>
                      <v-list-item
                        @click.stop="selectSuggestion(suggestion)"
                        class="suggestions-box"
                      >
                        <v-list-item-content
                          class="suggestions__item-header"
                          append-icon="mdi-check"
                        >
                          <div class="suggestions__header-wrapper">
                            <v-avatar
                              :max-width="24"
                              :max-height="24"
                              :min-height="24"
                              :min-width="24"
                              :color="category.avatarColor"
                              class="mr-2"
                              :data-pendo-id="
                                index === 0
                                  ? 'comply-suggestion-item-bubble'
                                  : null
                              "
                            >
                              <span v-if="suggestion.category === 'template'">
                                <v-icon :color="'white'">mdi-check</v-icon>
                              </span>
                              <span class="suggestions__item-id" v-else>
                                {{ index + 1 }}
                              </span>
                            </v-avatar>
                            <span class="suggestions__item-name">
                              {{
                                suggestion.type === 'image'
                                  ? 'Image - Brand image'
                                  : suggestion.name
                              }}
                            </span>
                            <div v-if="suggestion.status">
                              <v-icon :color="'#D9D9D9'">
                                mdi-circle-small
                              </v-icon>
                              <span class="suggestion-details__total">
                                {{
                                  suggestion.status === 'accepted'
                                    ? 'Accepted'
                                    : 'Rejected'
                                }}
                              </span>
                            </div>
                            <div v-if="suggestion.category === 'template'">
                              <v-icon :color="'#D9D9D9'">
                                mdi-circle-small
                              </v-icon>
                              <span class="suggestion-details__total">
                                {{ 'Accepted' }}
                              </span>
                            </div>
                          </div>
                        </v-list-item-content>
                        <v-list-item-icon v-if="!suggestion.status">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="suggestion.category !== 'template'"
                                icon
                                @click="onSuggestionAcceptClick(suggestion)"
                                v-bind="attrs"
                                v-on="on"
                                data-pendo-id="comply-suggestion-accept"
                              >
                                <v-icon color="#00925a">mdi-check</v-icon>
                              </v-btn>
                            </template>
                            <span>Accept</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-if="suggestion.category !== 'template'"
                                icon
                                @click.stop="
                                  onSuggestionRejectClick(suggestion)
                                "
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="#d32f2f">mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Reject</span>
                          </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-icon
                          v-if="isEditEnableAfterAction(suggestion)"
                        >
                          <v-tooltip
                            bottom
                            max-width="250"
                            content-class="tooltip-content"
                            fixed
                            close-delay="300"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="toggleColorPicker(suggestion)"
                                :data-pendo-id="
                                  !isActionLoading
                                    ? 'comply-edit-color-pencil'
                                    : ''
                                "
                              >
                                <v-icon color="primary" size="24"
                                  >mdi-eyedropper-variant</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>{{ 'Choose new color' }}</span>
                          </v-tooltip>
                        </v-list-item-icon>
                      </v-list-item>
                    </template>
                    <component
                      :is="
                        getComponentName(suggestion.type || suggestion.category)
                      "
                      :type="suggestion.type"
                      :suggestion="suggestion"
                      :category="category"
                      :itemIndex="index"
                    >
                    </component>
                  </v-list-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row v-if="getDeckFullComplianceInfo && !reviewClicked">
        <v-col>
          <div class="complied-message">
            <v-img
              max-height="45%"
              src="/assets/img/comply/brand-compliance-achieved.svg"
            ></v-img>
            <div class="complied-message__wrapper">
              <div>
                Congratulations! Your prezentation is
                <span class="score-percentage"> 100% </span>
                compliant with brand guidelines for your selected template.
              </div>
            </div>
            <div class="complied-message__actions">
              <v-btn
                rounded
                color="primary"
                class="app-button mr-2 download-btn"
                @click="onDownload"
              >
                Download
              </v-btn>
              <div class="d-flex flex-row justify-space-between align-center">
                <span>Need customization?</span>
                <v-btn
                  text
                  rounded
                  color="primary"
                  class="app-button mr-2 pl-1"
                  name--auto="review_details_deck_details_tab"
                  data-pendo-id="comply-deck-review-details"
                  @click="onReviewDetailsClick"
                >
                  Review details
                </v-btn>
              </div>
            </div>
            <!-- <div class="d-flex flex-row justify-space-between align-center">
              <p class="mb-0">Need customization?</p>
              <v-btn
                text
                rounded
                color="primary"
                class="app-button mr-2 pl-1"
                name--auto="review_details_deck_details_tab"
                @click="onReviewDetailsClick"
              >
                Review details
              </v-btn>
            </div> -->
          </div>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="!getDeckFullComplianceInfo">
      <v-col class="d-flex align-baseline">
        <div class="buttons-content mb-5">
          <v-btn
            rounded
            color="primary"
            class="app-button"
            data-pendo-id="comply-make-hundred-compliant-button"
            @click="applyFullDeckCompliance"
            >Accept all suggestions</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <ConfirmationModal
      v-if="showSuggestionAcceptRejectPopUp"
      dataPendoId="popup-confirm-slide-changes"
      :show="showSuggestionAcceptRejectPopUp"
      :title="acceptClick ? 'Accept Suggestion(s)' : 'Reject Suggestion(s)'"
      :text="
        acceptClick
          ? 'Changes will be applied to all the slides. Do you want to proceed?'
          : 'Changes will be rejected on all the slides. Do you want to proceed?'
      "
      :primaryBtnText="'Cancel'"
      :secondaryBtnText="'Proceed'"
      :onPrimaryClick="() => onPopUpClose()"
      :onSecondaryClick="() => onProceedClick()"
      :onClose="() => onPopUpClose()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import {
  downloadComplyDeck,
  sendEmail,
  updateEditSuggestionMetric,
} from '../../../../utils/api-helper';
// import { downloadFileWithCustomName } from '../../../../utils/common';
// import { getFileURL } from '@/utils/calyrex';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import ShapeColorSuggestion from './format-suggestions/ShapeColorSuggestion.vue';
import FontSizeSuggestion from './format-suggestions/FontSizeSuggestion.vue';
import HeaderFooterSuggestion from './format-suggestions/HeaderFooterSuggestion.vue';
import FontStyleSuggestion from './format-suggestions/FontStyleSuggestion.vue';
import ImageSuggestion from './format-suggestions/ImageSuggestion';
import TemplateSuggestion from './format-suggestions/TemplateSuggestion.vue';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_DOWNLOAD_CTA,
  TD_PLACEMENT,
  TD_NUMBEROFSLIDES,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_NO,
  TD_UPLAODED_HUNDRED_PERCENT_COMPLIANT_DECK,
  TD_DECK,
  TD_CATEGORY_REJECTED,
  TD_CATEGORY_ACCEPTED,
  TD_AFTER_CLICK_ON_REVIEW_DETAILS,
  TD_SUGGESTION_ACCEPTED,
  TD_SUGGESTION_REJECTED,
  TD_TEMPLATE_CONVERTER,
  TD_CROSS_FEATURE,
  TD_TIME_SPENT_IN_SECS,
  TD_MAKE_100_PERCENT_COMPLIANCE_ACCEPT_ALL_SUGGESTIONS,
  TD_ACCEPT_ALL_SUGGESTIONS,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import GenerateActionModal from '../../GenerateSlides/GenerateActionModal.vue';
import ComplyColorPicker from './format-suggestions/ComplyColorPicker';
import GetInTouchDetail from '../../../Main/Profile/GetInTouchDetail';
import { callMergeSlidesApi } from '../../../../utils/merge-slide-helper';
import { miscConfig } from '../../../../runtimeConfig';
import ConfirmationModal from '../../../common/ConfirmationDialog.vue';
import ReplaceComplyImages from '../replace-comply-images/ReplaceComplyImages';
import { ERROR_CODES } from '../utils/comply-helper.utils';

export default {
  components: {
    ShapeColorSuggestion,
    FontSizeSuggestion,
    HeaderFooterSuggestion,
    FontStyleSuggestion,
    ImageSuggestion,
    TemplateSuggestion,
    ComplyColorPicker,
    ConfirmationModal,
    ReplaceComplyImages,
  },
  data() {
    return {
      // displaySuccessScreen: false,
      downloadOperation: {
        loading: false,
      },
      reviewClicked: false,
      panel: null,
      subPanel: {
        color: null,
        font: null,
        images: null,
        template: null,
      },
      selectedColor: '',
      showSuggestionAcceptRejectPopUp: false,
      acceptClick: false,
      rejectClick: false,
      selectedSuggestionData: null,
      selectedCategory: null,
      categoryLevelAction: null,
    };
  },
  props: {
    clearSlideLibraryFlags: {
      type: Function,
    },
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    addToLibrary: {
      type: Function,
      default: () => ({}),
    },
    handleClosePopAndSaveToLib: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    selectedSuggestion(val) {
      if (val) {
        this.panel = val.categoryIndex;
        this.subPanel[val.category] = val.index;
      } else {
        this.subPanel = {
          color: null,
          font: null,
          images: null,
          template: null,
        };
      }
    },
    slideIndex() {
      this.reviewClicked = false;
      this.panel = null;
    },
  },
  computed: {
    ...mapGetters('comply', [
      'getSlidesInfo',
      'getComplianceScore',
      'getFileDetails',
      'getDeckFullComplianceInfo',
      'totalSlides',
      'getDeckLevelCategorySuggestions',
      'getReplaceImageSuggestionState',
      'isActionLoading',
    ]),
    ...mapState('comply', [
      'selectedTemplate',
      'fileDetails',
      'selectedSlides',
      'slidesInfo',
      'selectedSuggestion',
      'colorPickerState',
      'isDeckFullCompliantFlow',
    ]),
    ...mapGetters('users', [
      'isReplaceBrandImageEnabled',
      'allowReplaceImageOverall',
    ]),
    ...mapState('users', ['currentUser']),
    // ...mapState('comply', ['hasBrandImages']),
    complianceColor() {
      if (this.complianceScore < 50) {
        return '#D32F2F'; // Red for score < 50%
      }
      if (this.complianceScore >= 50 && this.complianceScore <= 75) {
        return '#FDD835'; // Yellow for score 50% - 75%
      }
      return '#00925A'; // Green for score > 75%
    },
    complianceScore() {
      return this.getComplianceScore;
    },
    // hasBrandImages() {
    //   const deckImgCat = this.getDeckLevelCategorySuggestions.find(
    //     (item) => item.key === 'images',
    //   );
    //   return (
    //     deckImgCat &&
    //     deckImgCat.suggestionsCount &&
    //     deckImgCat.suggestionsCount > 0
    //   );
    // },
  },
  methods: {
    ...mapActions('comply', [
      'applyFullDeckComplianceChanges',
      'acceptUserSelectedColor',
      'updateSlideSuggestions',
      'setSelectedSuggestion',
      'updateUserSelectedColor',
      'setColorPickerState',
      'setReplaceImageSuggestionState',
    ]),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
    ]),
    async applyDeckCompliance(categorySelected, action = 'accept') {
      console.log('category selected', categorySelected);
      let applyBGtoFont = [
        'bg_to_font_color',
        'bg_to_bullet_color',
        'bg_to_svg_color',
      ];
      if (categorySelected !== 'color') {
        applyBGtoFont = [];
      }
      const eventStartTime = new Date();
      try {
        const actionAfterReview =
          !this.isDeckFullCompliantFlow && action.toLowerCase() === 'accept'
            ? 'category-accept'
            : 'category-reject';
        await this.$store.dispatch(
          'comply/applyCategoryDeckComplianceChanges',
          {
            categorySelected,
            action,
            applyBGtoFont,
            actionAfterReview,
          },
        );
      } catch (error) {
        console.error('Error while processing deck compliance:', error);
        // this.$toast.error('Error applying deck compliance changes');
      }
      if (action === 'accept') {
        if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_COMMON_COLUMN_NAME]: TD_CATEGORY_ACCEPTED,
          [TD_COMMON_COLUMN_VALUE]:
            categorySelected.charAt(0).toUpperCase() +
            categorySelected.slice(1),
          [TD_PLACEMENT]: TD_DECK,
          [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
        };
        trackComplyEvents.complianceCheckerReviewDetailsCategoryAccept(
          this.currentUser,
          otherData,
        );
      }
      if (action === 'reject') {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_COMMON_COLUMN_NAME]: TD_CATEGORY_REJECTED,
          [TD_COMMON_COLUMN_VALUE]:
            categorySelected.charAt(0).toUpperCase() +
            categorySelected.slice(1),
          [TD_PLACEMENT]: TD_DECK,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
        };
        trackComplyEvents.complianceCheckerReviewDetailsCategoryReject(
          this.currentUser,
          otherData,
        );
      }
    },

    async applyFullDeckCompliance() {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      const eventStartTime = new Date();
      try {
        await this.$store.dispatch(
          'comply/applyFullDeckComplianceChanges',
          this.isDeckFullCompliantFlow ? true : '',
        );
        this.$store.dispatch('comply/setIsDeckFullCompliantFlow', false);
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_PLACEMENT]: TD_AFTER_CLICK_ON_REVIEW_DETAILS,
          [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
          [TD_COMMON_COLUMN_NAME]:
            TD_MAKE_100_PERCENT_COMPLIANCE_ACCEPT_ALL_SUGGESTIONS,
          [TD_COMMON_COLUMN_VALUE]: TD_ACCEPT_ALL_SUGGESTIONS,
        };
        trackComplyEvents.complianceCheckerMakeHundredCompliant(
          this.currentUser,
          otherData,
        );
        // this.displaySuccessScreen = true;
      } catch (error) {
        console.error('Error while processing deck compliance:', error);
        this.$store.dispatch('comply/resetState');
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        await this.$store.dispatch('comply/resetFileData');
        // this.$toast.error('Error applying deck compliance changes');
      }
    },

    onReviewDetailsClick() {
      this.reviewClicked = true;
    },
    onDownload() {
      const filename = `${this.getFileDetails.name.replace(
        /.pptx/g,
        '',
      )}-template-converter`;
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      this.$modal.show(
        GenerateActionModal,
        {
          heading:
            this.totalSlides > 1 ? 'Download prezentation' : 'Download slide',
          primaryBtnText: 'Download',
          modalType: 'download',
          primaryBtnAction: this.download,
          type: this.totalSlides > 1 ? 'prezentation' : 'slide',
          operation: this.downloadOperation,
          isAddedToLibrary: this.isAddedToLibrary,
          filename,
          downloadItem:
            this.totalSlides > 1
              ? {
                  name: filename,
                  categories: ['title'],
                  prefs: {
                    // ...this.selectedSlide.slideDetails.prefs,
                    source: 'comply',
                  },
                  filename,
                  noofslides: slides.length,
                  downloadLoaderId: slides[0]?.pptx?.s3_path,
                }
              : {
                  name: `${this.getFileDetails?.name.replace(/.pptx/g, '')}`,
                  prefs: {
                    source: 'comply',
                  },
                  filename: slides[0]?.pptx?.s3_path,
                  noofslides: slides.length,
                  downloadLoaderId: slides[0]?.pptx?.s3_path,
                },
          originPage: 'comply',
          getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
          closePreviousPopups: this.handleClosePopAndSaveToLib,
          logDownload: () => this.logMetricsOnSaveToGoogleDrive(),
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_PLACEMENT]: TD_DOWNLOAD_CTA,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        [TD_COMMON_COLUMN_NAME]: TD_UPLAODED_HUNDRED_PERCENT_COMPLIANT_DECK,
        [TD_COMMON_COLUMN_VALUE]: TD_NO,
      };
      trackComplyEvents.complianceCheckerDownload(this.currentUser, otherData);
    },
    async download(name, isFavorite, addToLibraryClick) {
      this.downloadOperation.loading = true;
      //
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      this.setDownloadLoaders({
        loaderId: slides[0]?.pptx?.s3_path,
        downloading: true,
      });
      this.setShowDownloadSnackbar(true);
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'tcDownload',
        logDownloadCallback: async () => {
          if (addToLibraryClick === true && !this.isAddedToLibrary) {
            await this.addToLibrary(name, false, 'download');
          }
        },
        downloadStatusCallback: () => {
          this.downloadOperation.loading = false;
          this.setDownloadLoaders({
            loaderId: slides[0]?.pptx?.s3_path,
            downloading: false,
          });
          this.$modal.hide('GenerateActionModal');
        },
        metaData: {
          ...this.getFileDetails,
          ...this.slidesInfo,
          fileName: name,
          limitsKey:
            this.slidesInfo?.slide_images?.length > 1
              ? 'prezentation'
              : 'slide',
          origin:
            this.slidesInfo?.slide_images?.length > 1
              ? 'prezentation'
              : 'slide',
          noofslides: this.slidesInfo?.slide_images?.length,
        },
      });
      //
      // if (addToLibraryClick === true && !this.isAddedToLibrary) {
      //   await this.addToLibrary(name, false, 'download');
      // }
      // const mergedUrl = await this.getMergedUrlForDownload();
      // downloadComplyDeck(this.getFileDetails.uploadId, mergedUrl).then(
      //   async () => {
      //     const fileUrl = await getFileURL(
      //       this.currentUser.user.id,
      //       mergedUrl,
      //       this.currentUser.userIp,
      //       'IOCUpload',
      //     );
      //     downloadFileWithCustomName(fileUrl, name);
      //     this.downloadOperation.loading = false;
      //     this.$modal.hide('GenerateActionModal');
      //   },
      // );
    },
    // async handleClosePopAndSaveToLib(name, addToLibraryClick) {
    //   if (addToLibraryClick === true && !this.isAddedToLibrary) {
    //     this.downloadOperation.loading = true;
    //     await this.addToLibrary(name, false, 'download');
    //     this.downloadOperation.loading = false;
    //   }
    //   this.$modal.hide('GenerateActionModal');
    // },
    async logMetricsOnSaveToGoogleDrive() {
      console.log('DeckDetailsTab- downloadComplyDeck');
      const s = {
        slides: [],
        outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        outputPath: `private/template-converter/comply/${this.getFileDetails.uploadId}/mergeslides/`,
      };

      const slides = Object.values(this.slidesInfo?.slide_images || {});
      slides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let s3url = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          s3url = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      const url = `private/template-converter/comply/${this.getFileDetails.uploadId}/mergeslides/${s3url}`;
      console.log(
        'DeckDetailsTab - logMetricsOnSaveToGoogleDrive url ===>',
        url,
      );
      await downloadComplyDeck(this.getFileDetails.uploadId, url);
    },
    onGetInTouchclick() {
      try {
        const userName = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;

        const params = {
          type: 'brand-image-request',
          meta: {
            // message: mBody,
            requester: this.currentUser.user.firstName,
            // subject: `${userName} has expressed interest in adding brand images`,
            requesterFullName: userName,
            requesterID: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.error(err);
      }

      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thankForYourInterestInBrandImages',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
        },
      );
    },
    getSuggestionsCountText(category) {
      if (this.isSuggestionsResolved(category))
        return 'All suggestions resolved';
      if (category.key === 'template') {
        return 'All suggestions resolved';
      }
      const suggestionsCount = this.getSuggestionCount(category);
      if (suggestionsCount === 0) return 'No suggestions available';
      return suggestionsCount === 1
        ? `${suggestionsCount} suggestion unresolved`
        : `${suggestionsCount} suggestions unresolved`;
    },

    isSuggestionsResolved(category) {
      return category.resolvedSuggestions === category.totalSuggestions;
    },

    disableSuggestionsDropdown(category) {
      return (
        this.getSuggestionsCountText(category) === 'No suggestions available'
      );
    },
    async getMergedUrlForSaveToGdrive() {
      const s = {
        slides: [],
      };
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      slides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },
    // async getMergedUrlForDownload() {
    //   console.log();
    //   const s = {
    //     slides: [],
    //     outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
    //     outputPath: `private/template-converter/comply/${this.fileDetails.uploadId}/mergeslides/`,
    //   };
    //   const slides = Object.values(this.slidesInfo?.slide_images || {});
    //   slides.forEach((slide) => {
    //     s.slides.push({
    //       slideId:
    //         slide?.pptx?.s3_path?.charAt(0) === '/'
    //           ? slide?.pptx?.s3_path?.substring(1)
    //           : slide?.pptx?.s3_path,
    //       isReplaced: false,
    //       isUploaded: false,
    //       isGenerated: false,
    //       isSynthesis: false,
    //       isRedesign: false,
    //       isComply: true,
    //     });
    //   });
    //   let mergedUrl = '';
    //   await callMergeSlidesApi(s)
    //     .then((res) => {
    //       const mergedslide = res.data;
    //       if (mergedslide.includes('.pptx')) {
    //         mergedUrl = mergedslide;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   return `private/template-converter/comply/${this.fileDetails.uploadId}/mergeslides/${mergedUrl}`;
    // },

    selectSuggestion(suggestion) {
      if (
        this.selectedSuggestion &&
        this.selectedSuggestion.index === suggestion.index &&
        this.selectedSuggestion.category === suggestion.category
      ) {
        this.setSelectedSuggestion(null);
      } else {
        this.setSelectedSuggestion({
          index: suggestion.index,
          categoryIndex: suggestion.categoryIndex,
          category: suggestion.category,
        });
      }
      // this.selectedSuggestion = suggestion;
    },

    convertSuggestedForImage(formatSuggestions, mapIndex, sugg) {
      return [
        {
          ...formatSuggestions[0],
          mapping: [
            {
              ...formatSuggestions[0].mapping[mapIndex],
              suggested:
                sugg.origin === 'Brand'
                  ? sugg
                  : formatSuggestions[0].mapping[mapIndex].suggested[0],
            },
          ],
        },
      ];
    },

    async onGroupAcceptClick() {
      let slidePatchData = this.createSlidePatchData(
        this.selectedSuggestionData.suggestionsList,
      );
      slidePatchData.action = 'accept';
      slidePatchData.actionAfterReview = !this.isDeckFullCompliantFlow
        ? 'suggestion-accept'
        : '';
      const colorsCategorySuggestions =
        this.getDeckLevelCategorySuggestions.find((s) => s.key === 'color');
      // apply bg to font color if there is only one suggestion left out inside the color suggestions.
      const unresolvedColorSuggestions =
        colorsCategorySuggestions.suggestions.filter((s) => s.status === null);
      if (
        (colorsCategorySuggestions !== undefined &&
          unresolvedColorSuggestions.length === 1 &&
          slidePatchData.category[0] === 'color') ||
        (unresolvedColorSuggestions.length === 0 &&
          this.colorPickerState.pickingAfterReview &&
          slidePatchData.category[0] === 'color')
      ) {
        slidePatchData = {
          ...slidePatchData,
          ...{
            applyBGtoFont: [
              'bg_to_font_color',
              'bg_to_bullet_color',
              'bg_to_svg_color',
            ],
          },
        };
      }
      try {
        await this.$store.dispatch(
          'comply/applyFullDeckChangesOnAcceptOrRejectAction',
          {
            deckData: slidePatchData,
          },
        );
        this.updateSlideSuggestions({
          category: this.selectedSuggestionData.category,
          categorySubIndex: this.selectedSuggestionData.categorySubIndex,
          mappingIndex: this.selectedSuggestionData.mappingIndex,
          isAccepted: true,
          suggestedToUpdate: this.selectedSuggestionData.suggested[0],
        });
      } catch (error) {
        console.log(error);
        // this.$toast.error('Error applying compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(
          this.selectedSuggestionData.type,
        );
        this.snackbarMessage = `Accepted ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_ACCEPTED,
        [TD_COMMON_COLUMN_VALUE]: this.selectedSuggestionData.name,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionAccept(
        this.currentUser,
        otherData,
      );
    },

    async onAcceptClick(suggestion, selectedColor = '') {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      const allColorSuggestions = [
        ...this.slidesInfo.format_suggestions[suggestion.category],
      ];
      let formatSuggestions = [
        {
          ...allColorSuggestions[suggestion.categorySubIndex],
        },
      ];
      if (formatSuggestions[0].type === 'image') {
        formatSuggestions = this.convertSuggestedForImage(
          formatSuggestions,
          suggestion.mappingIndex,
          suggestion.suggested[0],
        );
      } else if (selectedColor) {
        let matchedMappings = [];
        const suggestedMapping =
          formatSuggestions[0].mapping[suggestion.mappingIndex];
        suggestedMapping.suggested = `ff${selectedColor}`;
        if (this.colorPickerState?.pickingAfterReview) {
          suggestedMapping.current = this.colorPickerState.suggested;
          suggestedMapping.old_color = this.colorPickerState.current;
          const remainingMappings = this.slidesInfo.format_suggestions[
            suggestion.category
          ][suggestion.categorySubIndex].mapping.filter(
            (s) => s.mappingIndex !== suggestion.mappingIndex,
          );
          matchedMappings = remainingMappings.filter(
            (mapping) => suggestedMapping.current === mapping.suggested,
          );
          console.log('suggested mapping', suggestedMapping);
          console.log('matched mappings', matchedMappings);
          if (matchedMappings.length) {
            matchedMappings.map((match) => {
              const currentColorVal = match.current;
              match.old_color = currentColorVal;
              match.suggested = suggestedMapping.suggested;
              match.current = suggestedMapping.current;
              return match;
            });
          }
        }
        formatSuggestions[0].mapping = [suggestedMapping, ...matchedMappings];
      } else {
        formatSuggestions[0].mapping = [
          formatSuggestions[0].mapping[suggestion.mappingIndex],
        ];
      }
      let slidePatchData = this.createSlidePatchData(
        formatSuggestions,
        this.selectedSuggestionData?.category,
      );
      slidePatchData.action = this.colorPickerState?.pickingAfterReview
        ? 're-accept'
        : 'accept';
      slidePatchData.actionAfterReview =
        !this.isDeckFullCompliantFlow && slidePatchData.action === 'accept'
          ? 'suggestion-accept'
          : '';
      if (slidePatchData.category[0] === 'images') {
        slidePatchData.include_image_conversions = true;
      }
      const colorsCategorySuggestions =
        this.getDeckLevelCategorySuggestions.find((s) => s.key === 'color');
      // apply bg to font color if there is only one suggestion left out inside the color suggestions (or) if the entire color category is already accepted and user is re-editing it.
      const unresolvedColorSuggestions =
        colorsCategorySuggestions.suggestions.filter((s) => s.status === null);
      console.log('on accept', unresolvedColorSuggestions);
      if (
        (colorsCategorySuggestions !== undefined &&
          unresolvedColorSuggestions.length === 1 &&
          slidePatchData.category[0] === 'color') ||
        (unresolvedColorSuggestions.length === 0 &&
          this.colorPickerState.pickingAfterReview &&
          slidePatchData.category[0] === 'color')
      ) {
        slidePatchData = {
          ...slidePatchData,
          ...{
            applyBGtoFont: [
              'bg_to_font_color',
              'bg_to_bullet_color',
              'bg_to_svg_color',
            ],
          },
        };
      }
      try {
        await this.$store.dispatch(
          'comply/applyFullDeckChangesOnAcceptOrRejectAction',
          {
            deckData: slidePatchData,
          },
        );
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: true,
          suggestedToUpdate: suggestion.suggested[0],
        });
      } catch (error) {
        console.log(error);
        // this.$toast.error('Error applying compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Accepted ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_ACCEPTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionAccept(
        this.currentUser,
        otherData,
      );
    },

    createSlidePatchData(data, category = '') {
      return {
        upload_id: this.fileDetails.uploadId,
        source: this.isDeckFullCompliantFlow
          ? 'deck-compliance'
          : 'template-conversion',
        category: category ? [`${category}`] : ['color'],
        slide_id: this.slideId,
        reference_template: this.selectedTemplate,
        filename: this.getFileDetails.name,
        format_properties: {
          format_suggestions: data,
        },
        legal_properties: {
          legal_suggestions: [],
        },
        content_properties: {
          content_suggestions: [],
        },
        action: '',
        isMake100Percent: this.isDeckFullCompliantFlow ? true : '',
      };
    },

    replaceUnderscoreWithSpace(str) {
      return str.replace(/_/g, ' ');
    },

    async onGroupRejectClick(suggestion) {
      const slidePatchData = this.createSlidePatchData(
        suggestion.suggestionsList,
      );
      slidePatchData.action = 'reject';
      slidePatchData.actionAfterReview = !this.isDeckFullCompliantFlow
        ? 'suggestion-reject'
        : '';
      try {
        await this.$store.dispatch(
          'comply/applyFullDeckChangesOnAcceptOrRejectAction',
          {
            deckData: slidePatchData,
          },
        );
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: false,
        });
      } catch (err) {
        console.error('Error while processing all suggestions reject:', err);
        // this.$toast.error('Error applying all compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Dismissed ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_REJECTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionReject(
        this.currentUser,
        otherData,
      );
    },

    async onCancelClick(suggestion) {
      // this.$set(item, 'status', 'rejected');
      let formatSuggestions = [
        {
          ...this.slidesInfo?.format_suggestions[suggestion.category][
            suggestion.categorySubIndex
          ],
        },
      ];
      if (formatSuggestions[0].type === 'image') {
        formatSuggestions = this.convertSuggestedForImage(
          formatSuggestions,
          suggestion.mappingIndex,
          suggestion.suggested[0],
        );
      } else {
        formatSuggestions[0].mapping = [
          formatSuggestions[0].mapping[suggestion.mappingIndex],
        ];
      }
      const slidePatchData = this.createSlidePatchData(
        formatSuggestions,
        formatSuggestions[0].type === 'image' ? 'images' : '',
      );
      slidePatchData.action = 'reject';
      slidePatchData.actionAfterReview = !this.isDeckFullCompliantFlow
        ? 'suggestion-reject'
        : '';
      if (slidePatchData.category[0] === 'images') {
        slidePatchData.include_image_conversions = true;
      }
      try {
        await this.$store.dispatch(
          'comply/applyFullDeckChangesOnAcceptOrRejectAction',
          {
            deckData: slidePatchData,
          },
        );
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: false,
        });
      } catch (err) {
        console.error('Error while processing all suggestions reject:', err);
        // this.$toast.error('Error applying all compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Dismissed ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_REJECTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionReject(
        this.currentUser,
        otherData,
      );
    },

    getComponentName(type) {
      const componentNames = {
        color_fill: 'ShapeColorSuggestion',
        color_outline: 'ShapeColorSuggestion',
        font_size: 'FontSizeSuggestion',
        font_color: 'ShapeColorSuggestion',
        header_footer: 'HeaderFooterSuggestion',
        font_style: 'FontStyleSuggestion',
        image: 'ImageSuggestion',
        template: 'TemplateSuggestion',
        group: 'ShapeColorSuggestion',
      };
      return componentNames[type] || null;
    },

    acceptColorPicker() {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      this.onAcceptClick(this.selectedSuggestionData, this.selectedColor);
      this.resetColorPicker();
    },
    closeColorPicker() {
      this.resetColorPicker();
    },
    rejectColorPicker() {
      this.resetColorPicker();
      this.onCancelClick(this.selectedSuggestionData);
    },

    updateSelectedColor(suggestion, newColor) {
      const color =
        newColor && newColor.length === 6 ? `ff${newColor}` : newColor;
      this.updateUserSelectedColor({
        suggestion,
        userSelectedColor: color || undefined,
      });
    },

    resetColorPicker() {
      this.setColorPickerState({
        suggestion: null,
        selectedColor: null,
        panelOpen: false,
        pickingAfterReview: false,
      });
    },
    onSuggestionAcceptClick(suggestion) {
      this.acceptClick = true;
      this.selectedSuggestionData = suggestion;
      this.showSuggestionAcceptRejectPopUp = true;
    },
    onSuggestionRejectClick(suggestion) {
      this.rejectClick = true;
      this.selectedSuggestionData = suggestion;
      this.showSuggestionAcceptRejectPopUp = true;
    },
    onSuggestionAcceptFromPicker(colorPickerState) {
      this.acceptClick = true;
      this.selectedSuggestionData = colorPickerState.suggestion;
      this.selectedColor = colorPickerState.selectedColor;
      this.showSuggestionAcceptRejectPopUp = true;
    },
    onSuggestionRejectFromPicker(colorPickerState) {
      this.rejectClick = true;
      this.selectedSuggestionData = colorPickerState.suggestion;
      this.showSuggestionAcceptRejectPopUp = true;
    },
    async onProceedClick() {
      if (this.selectedCategory && this.categoryLevelAction) {
        this.showSuggestionAcceptRejectPopUp = false;
        await this.applyDeckCompliance(
          this.selectedCategory.key,
          this.categoryLevelAction,
        );
        this.selectedCategory = null;
        this.categoryLevelAction = null;
      } else {
        if (this.acceptClick) {
          this.showSuggestionAcceptRejectPopUp = false;
          if (this.selectedColor) {
            this.acceptColorPicker();
          } else if (this.selectedSuggestionData.isGroupSuggestion) {
            await this.onGroupAcceptClick();
          } else {
            await this.onAcceptClick(this.selectedSuggestionData);
          }
          this.selectedSuggestionData = null;
          this.selectedColor = '';
          this.acceptClick = false;
        }
        if (this.rejectClick) {
          this.showSuggestionAcceptRejectPopUp = false;
          if (this.selectedSuggestionData.isGroupSuggestion) {
            await this.onGroupRejectClick(this.selectedSuggestionData);
          } else {
            this.resetColorPicker();
            await this.onCancelClick(this.selectedSuggestionData);
          }
          this.selectedSuggestionData = null;
          this.selectedColor = '';
          this.rejectClick = false;
        }
      }
    },
    onPopUpClose() {
      this.acceptClick = false;
      this.rejectClick = false;
      this.showSuggestionAcceptRejectPopUp = false;
      this.selectedSuggestionData = null;
      this.selectedColor = '';
      this.selectedCategory = null;
      this.categoryLevelAction = null;
    },
    isEditEnableAfterAction(suggestion) {
      return (
        (suggestion.category === 'color' &&
          suggestion.status &&
          suggestion.status.toLowerCase() === 'accepted' &&
          !suggestion.isGroupSuggestion &&
          suggestion.enable_color_picker) ||
        (this.getDeckFullComplianceInfo &&
          suggestion.category === 'color' &&
          !suggestion.isGroupSuggestion)
      );
    },
    async toggleColorPicker(suggestion) {
      console.log('suggestion color', suggestion);
      const currentAcceptedColor = suggestion.suggested;
      this.setColorPickerState({
        suggestionNumber: suggestion.index,
        panelOpen: true,
        current: suggestion.current,
        suggested: suggestion?.suggested,
        suggestion: {
          ...suggestion,
          ...{ suggested: '' },
          ...{ current: currentAcceptedColor },
        },
        itemIndex: this.itemIndex,
        selectedColor: suggestion?.userSelectedColor,
        pickingAfterReview: true,
      });
      // posting update metric
      await updateEditSuggestionMetric(this.fileDetails.uploadId);
    },

    async handleAcceptFromBrandImageLib(suggestion) {
      this.selectedSuggestionData = suggestion;
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      await this.onAcceptClick(suggestion);
      this.setReplaceImageSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    handleRejectFromBrandImageLib(suggestion) {
      this.selectedSuggestionData = suggestion;
      this.onCancelClick(suggestion);
      this.setReplaceImageSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    selectCategory(category) {
      this.$nextTick(() => {
        if (this.panel !== category.index) {
          this.setSelectedSuggestion({
            index: 1,
            categoryIndex: category.index,
            category: category.key,
          });
        } else {
          this.setSelectedSuggestion(null);
        }
      });
    },

    onCategoryAccept(category) {
      this.acceptClick = true;
      this.selectedCategory = category;
      this.categoryLevelAction = 'accept';
      this.showSuggestionAcceptRejectPopUp = true;
    },
    onCategoryReject(category) {
      this.rejectClick = true;
      this.selectedCategory = category;
      this.categoryLevelAction = 'reject';
      this.showSuggestionAcceptRejectPopUp = true;
    },
    getSuggestionCount(category) {
      return category.suggestions.filter((sugg) => sugg.status === null).length;
    },

    getSuggestionDescription(category) {
      const suggestionsCount = this.getSuggestionCount(category);
      switch (category.key) {
        case 'color':
          return suggestionsCount > 0
            ? `Use template colors in ${suggestionsCount} elements across your prezentation`
            : `Your presentation follows all brand guidelines on colors`;
        case 'font':
          return suggestionsCount > 0
            ? ` Apply template font styles to ${suggestionsCount} elements across your prezentation`
            : `Your presentation follows all brand guidelines on font styles`;
        case 'images':
          return suggestionsCount > 0
            ? `Utilize brand images for ${suggestionsCount} slides in your prezentation`
            : `Your presentation follows all brand guidelines on brand images`;
        case 'template':
          return 'Your prezentation follows all brand guidelines on master template';
        default:
          return '';
      }
    },
  },
  mounted() {
    this.$store.dispatch('comply/setIncludeImageConversion', true);
  },
};
</script>
<style lang="scss" scoped>
.text-link {
  background: none;
  border: none;
  padding: 1px !important;
  font-size: inherit;
  cursor: pointer;
  text-transform: none;
  letter-spacing: normal;
  pointer-events: auto;
}
.compliance-score-box {
  padding: 16px 0;
  margin: 16px;
}

.slide-compliance-score-box {
  border-radius: 8px;
  background: #f2f4f8;
  align-items: center;
}

.score-text-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-text {
  font-size: 16px;
  font-weight: 600;
}

.score-percentage {
  font-size: 20px;
  font-weight: 700;
}

.list-wrapper {
  border-radius: 8px;
  border: 1px solid #f2f4f8;
  height: 88px;
  margin-bottom: 16px;

  ::v-deep .v-list-item-content {
    padding: 16px 0;
  }
}

.suggestion-details {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 16px;
  gap: 8px;
  height: 24px;
  width: 442px;
  justify-content: space-between;
  // margin-top: 12px;

  &__total {
    color: #697077;
    font-size: 12px;
  }

  &__item-text {
    margin-left: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 2px;
  }

  &__description {
    color: #212121;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin: 18px auto 0 0;
    font-size: 14px;
  }

  ::v-deep .v-list-item__action--stack {
    align-items: flex-end;
    align-self: center;
    white-space: nowrap;
    flex-direction: row;

    > button {
      margin-right: 10px; // Adds space between buttons, adjust the value as needed
    }
  }
}

.score {
  margin-left: 8px;
}

.app-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
}

.complied-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 40px;
  justify-content: center;

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 14px;
    max-width: 70%;
    text-align: center;
  }

  &__description {
    font-weight: 600;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .score-percentage {
    color: #21a7e0;
    font-size: 20px;
    font-weight: 700;
  }
}

.loader-wrapper {
  color: #000;
  display: flex;
  width: 540px;
  height: 120px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;

  border-radius: 12px;
  background: var(--color-styles-old-white, #fff);

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16),
    0px 0px 6px 0px rgba(0, 0, 0, 0.16);
}

::v-deep .v-expansion-panel::before {
  border: 1px solid #f2f4f8;
  box-shadow: none;
}
::v-deep .v-expansion-panel::after {
  border-top: none;
}

::v-deep
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 4px;
  border-radius: 8px;
  border: 1px solid var(--new-palette-primary-primary-99, #f2f4f8);
}

::v-deep .v-expansion-panel--active .v-expansion-panel-header {
  border: none !important;
}
::v-deep .v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    .v-list {
      .v-list-group {
        .v-list-group__header {
          .suggestions-box {
            .v-list-item__content {
              .suggestions__header-wrapper {
                display: flex;
                align-items: center;
                div:last-child {
                  display: flex;
                  align-items: center;
                  // span {
                  //   line-height: 30px;
                  // }
                }
              }
            }
            .v-icon {
              display: block !important;
            }
          }
        }
      }
    }
  }
  border: none !important;
}

::v-deep
  .v-list-group--active
  > .v-list-group__header
  > .v-list-group__header__append-icon
  .v-icon {
  color: #757575 !important;
}

::v-deep
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0px !important;
  margin-top: 8px !important;
}

.suggestions {
  &__total-wrapper {
    display: flex;
    width: 212px;
    height: 35px;
    padding: 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #f2f4f8;
    margin-left: auto;
    justify-content: center;
  }
  &__total-number {
    color: #1075ab;
  }
  &__panel {
    margin-bottom: 16px;

    ::v-deep .v-expansion-panel-header {
      border-radius: 8px;
      border: 1px solid#F2F4F8;
    }
    ::v-deep .v-expansion-panel-header__icon {
      margin-top: -33px;
    }
  }
  &__item-header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  &__header-wrapper {
    display: flex;
    align-items: baseline;
  }
  &__item-id {
    color: #fff;
  }
  &__item-name {
    color: #000;
  }
}

.suggestions-box {
  border-top: 1px solid #f2f4f8;
  min-width: 100%;
  height: 68px;
}

.suggestion-details {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
  &__total {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    color: #697077;
  }
  &__item-text {
    margin-left: 10px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 2px;
  }
}

.expansionPanelsSuggestions {
  max-height: calc(100vh - 400px);
  overflow: auto;
  margin: 0px 0px 20px 0px;
  .row {
    width: 100% !important;
    margin: 0 !important;
  }

  ::v-deep .v-expansion-panel--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.download-btn {
  width: 138px;
  align-self: center;
}
</style>
