var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.suggestion.isGroupSuggestion)?_c('div',[_c('div',{class:{
      'color-fill-suggestion adjust-left-with-color-picker': !_vm.hideColorPicker,
      'color-fill-suggestion adjust-left-without-color-picker':
        _vm.hideColorPicker,
    }},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"block__wrapper"},[_c('span',{staticClass:"block__text"},[_vm._v(_vm._s(_vm.suggestionTextLeft))]),_c('div',{staticClass:"color-and-details preview-shape-container1"},[_c('PreviewShape',{staticClass:"preview-shape1",attrs:{"color":'#' + _vm.currentColor,"suggestionName":_vm.suggestionName}})],1)])]),_c('div',{staticClass:"arrow"},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"block__wrapper"},[_c('span',{staticClass:"block__text"},[_vm._v(_vm._s(_vm.suggestionTextRight))]),_c('div',{staticClass:"color-and-details preview-shape-container1"},[_c('PreviewShape',{staticClass:"preview-shape1",attrs:{"color":'#' + _vm.suggestedColor,"suggestionName":_vm.suggestionName}})],1)])]),(!_vm.hideColorPicker)?_c('div',{staticClass:"icon-box"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(!!!_vm.suggestion.status)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-pendo-id":!_vm.isActionLoading ? 'comply-edit-color-pencil' : ''},on:{"click":function($event){return _vm.toggleColorPicker(_vm.suggestion)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-eyedropper-variant")])],1):_vm._e()]}}],null,false,619074470)},[_c('span',[_vm._v(_vm._s('Choose new color'))])])],1):_vm._e()])]):_c('div',{staticClass:"group-suggestion-description"},[_vm._v(" "+_vm._s(_vm.suggestion.description)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }