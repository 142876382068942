<template>
  <div
    :class="`comply-upload-section fl-upldr-bx ${
      isActionLoading ? 'blur-effect' : ''
    }`"
  >
    <div
      class="comply-upload-section__left"
      @click.self="onComplyUploadDivClick()"
    >
      <p class="comply-upload-section__example-label" v-if="fileError">
        {{ $t(fileError) }}
      </p>
      <p
        class="comply-upload-section__example-label"
        v-else-if="!getFileName()"
        @click="onComplyUploadDivClick()"
      >
        Upload your PowerPoint slides (.pptx)
      </p>
      <v-tooltip bottom fixed v-else>
        <template v-slot:activator="{ on, attrs }">
          <p class="comply-upload-section__file-name" v-bind="attrs" v-on="on">
            {{ getFileName() }}
          </p>
        </template>
        <span>{{ getFileName() }}</span>
      </v-tooltip>
      <label
        class="comply-upload-section__size-limit-text"
        v-if="!getComplianceScore"
      >
        <span>
          Template Converter works best with decks having up to 20 slides and
          file size under 200MB.
        </span>
      </label>
      <div class="quick-actions" v-else>
        <a
          v-on:click="onChangeTemplate()"
          data-pendo-id="comply-change-template-btn-top-panel"
          ><v-icon color="#21A7E0">mdi-compare</v-icon> Change template</a
        >
      </div>
    </div>
    <div v-if="!getComplianceScore">
      <div class="comply-upload-section__right" v-if="!fileDetails">
        <div data-pendo-id="comply-upload-file-icon">
          <v-file-input
            ref="complyFileUploadInput"
            class="comply-upload-section__upload-icon"
            :accept="`${allowedUploadTypes}`"
            prepend-icon="mdi-tray-arrow-up"
            multiple
            label="File input"
            hide-input
            @change="previewFiles"
          ></v-file-input>
        </div>
        <input
          type="file"
          id="hiddenInput"
          :accept="`${allowedUploadTypes.join(', ')}`"
          @change="handleOnDivClickFileSelection"
          style="display: none"
        />
        <v-btn
          color="#21A7E0"
          class="comply-upload-section__check-compliance-btn"
          rounded
          :disabled="
            (!newFilesToUpload.length ||
              !!fileError.length ||
              slidesThresholdExceeds) &&
            !checkingUsage
          "
          :loading="showLoader"
          data-pendo-id="comply-upload-check-compliance-btn"
          @click="onComplianceBtnClick()"
          >Upload</v-btn
        >
      </div>
      <v-btn
        class="comply-upload-section__cancel-btn"
        @click="onCancel"
        rounded
        outlined
        v-else
        >Cancel</v-btn
      >
    </div>
    <div class="actions-container" v-else>
      <div class="re-check">
        <v-icon @click="onCancel">mdi-close</v-icon>
        <v-btn
          :loading="loading"
          class="re-check-cta"
          @click="handleOnReCheckComply('recheck-compliance')"
          rounded
          outlined
          >Recheck compliance</v-btn
        >
      </div>
      <v-menu
        v-model="feedbackPopup"
        :close-on-content-click="false"
        :nudge-width="300"
        offset-y
        left
        content-class="generate-rating-popover"
      >
        <template v-slot:activator="{ attrs }">
          <div
            class="generate-rating-btn-container"
            data-pendo-id="comply-rating-btn"
            v-bind="attrs"
          >
            <div>
              <v-hover v-slot="{ hover }">
                <v-btn
                  icon
                  @click.stop="
                    form.isLiked === null ? handleRatingClick('like') : () => {}
                  "
                  :class="{
                    bounce: animateLike,
                    'rating-disabled': form.isLiked !== null,
                  }"
                >
                  <v-tooltip bottom v-model="likeTooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="
                          (hover && form.isLiked === null) ||
                          form.isLiked === true ||
                          animateLike
                            ? '#21a7e0'
                            : '#000'
                        "
                        size="24"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          form.isLiked === true || animateLike
                            ? 'mdi-thumb-up'
                            : 'mdi-thumb-up-outline'
                        }}
                      </v-icon>
                    </template>
                    <span>{{
                      animateLike
                        ? 'Rate brand compliance suggestions'
                        : 'I like the suggestions!'
                    }}</span>
                  </v-tooltip>
                </v-btn>
              </v-hover>
            </div>
            <div>
              <v-hover v-slot="{ hover }">
                <v-btn
                  icon
                  :class="{ 'rating-disabled': form.isLiked !== null }"
                  @click.stop="
                    form.isLiked === null
                      ? handleRatingClick('dislike')
                      : () => {}
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="
                          (hover && form.isLiked === null) ||
                          form.isLiked === false
                            ? '#21a7e0'
                            : '#000'
                        "
                        size="24"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          form.isLiked === false
                            ? 'mdi-thumb-down'
                            : 'mdi-thumb-down-outline'
                        }}
                      </v-icon>
                    </template>
                    <span> I don’t like the suggestions!</span>
                  </v-tooltip>
                </v-btn>
              </v-hover>
            </div>
          </div>
        </template>
        <v-card>
          <v-btn
            elevation="0"
            icon
            class="generate-rating-popover__close"
            @click="closeFeedback"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div v-if="feedbackSuccess" class="feedback-success">
            <img src="/assets/img/generate/feedback_thanks.png" />
            <span>Thanks for your feedback!</span>
          </div>
          <template v-else>
            <div class="generate-rating-popover__content">
              <v-form ref="form" lazy-validation>
                <div>
                  <label class="form__label">
                    {{ $t('generate.feedbackIsInvaluable') }}
                  </label>
                  <v-textarea
                    v-model="form.feedback"
                    label="Type your feedback"
                    solo
                    :rows="4"
                    class="form__textarea"
                    required
                  />
                </div>
              </v-form>
            </div>

            <v-card-actions class="form__action">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                style="text-transform: none !important"
                rounded
                class="primary-button"
                width="100"
                height="48"
                :disabled="form.feedback.length === 0"
                @click="submitRatings"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
// import Upload from '../OvernightPrezentations/Upload.vue';
import { v4 as uuidv4 } from 'uuid';
import { trackComplyEvents } from '../../common/Analytics/ComplyEvents';
import {
  TD_PLACEMENT,
  TD_UPLOAD_SECTION,
  TD_UPLOAD_CTA,
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_THUMBS_DOWN,
  TD_THUMBS_UP,
  TD_THUMB_SELECTED,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_INSTRUCTIONS,
  TD_SLIDE_SELECTION,
  TD_TEMPLATE_SELECTION,
  TD_FINAL_STEP,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import {
  getFeatureUsage,
  submitComplyRatings,
  addComplyLog,
  complyDislike,
} from '../../../utils/api-helper';
import EventBus from '../../common/event-bus';
import { removeSpecialCharacters } from '../../utils';

export default {
  name: 'ManageComplyUpload',
  data() {
    return {
      checkingUsage: false,
      allowedUploadTypes: ['.pptx'],
      localFiles: [],
      newFilesToUpload: [],
      feedbackPopup: false,
      feedbackSuccess: false,
      form: {
        isLiked: null,
        feedback: '',
      },
      animateLike: false,
      likeTooltip: false,
      fileError: '',
      slidesThresholdExceeds: false,
    };
  },
  props: {
    handleOnFileUpload: {
      type: Function,
      default: () => {},
    },
    handleOnReCheckComply: {
      type: Function,
      default: () => {},
    },
    resetLocalFileInfo: {
      type: Boolean,
      default: false,
    },
    inTemplateSelectionStep: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', [
      'fileDetails',
      'isFileUploadLoading',
      'selectedTemplate',
      'selectedSlides',
    ]),
    ...mapGetters('comply', [
      'getComplianceScore',
      'isActionLoading',
      'getFileDetails',
    ]),
    showLoader() {
      return this.loading || this.checkingUsage || this.isFileUploadLoading;
    },
  },
  watch: {
    getComplianceScore(newVal, oldVal) {
      if (!oldVal && newVal) {
        setTimeout(() => {
          this.animateLike = true;
          this.likeTooltip = true;
          setTimeout(() => {
            this.likeTooltip = false;
            this.animateLike = false;
          }, 3000);
        }, 4000);
      }
    },
    feedbackPopup(val) {
      if (!val && !this.feedbackSuccess) {
        const payload = {
          id: this.fileDetails.uploadId,
          user_rating: this.form.isLiked,
          user_feedback: '',
        };
        submitComplyRatings(payload).then((resp) => {
          console.log(resp);
        });
      }
    },
    resetLocalFileInfo: {
      handler(val) {
        if (val) this.newFilesToUpload = [];
      },
    },
    getFileDetails: {
      handler(val) {
        if (!val) this.newFilesToUpload = [];
      },
    },
  },
  methods: {
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    ...mapActions('comply', ['setPlacement']),
    submitRatings() {
      this.feedbackSuccess = true;
      const payload = {
        id: this.fileDetails.uploadId,
        user_feedback: this.form.feedback,
      };
      const data = {
        uuid: this.fileDetails?.uploadId,
        userFeedback: this.form.feedback,
      };
      complyDislike(data).then((resp) => {
        console.log(resp);
      });
      submitComplyRatings(payload).then((resp) => {
        console.log(resp);
      });
      const otherData = {
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_THUMB_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: this.form.isLiked
          ? TD_THUMBS_UP
          : TD_THUMBS_DOWN,
        [TD_INSTRUCTIONS]: this.form.feedback,
      };
      trackComplyEvents.complianceCheckerFeedbackSubmitted(
        this.currentUser,
        otherData,
      );
    },
    handleRatingClick(type) {
      this.form.feeedback = '';
      this.form.isLiked = type === 'like';
      this.$nextTick(() => {
        this.feedbackSuccess = false;
        setTimeout(() => {
          this.feedbackPopup = true;
        }, 300);
      });
      const otherData = {
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_THUMB_SELECTED,
        [TD_COMMON_COLUMN_VALUE]: this.form.isLiked
          ? TD_THUMBS_UP
          : TD_THUMBS_DOWN,
      };
      trackComplyEvents.complianceCheckerFeedbackClick(
        this.currentUser,
        otherData,
      );
      const data = {
        uuid: this.fileDetails?.uploadId,
        userRating: false,
      };
      complyDislike(data).then((resp) => {
        console.log(resp);
      });
      const payload = {
        id: this.fileDetails.uploadId,
        user_rating: this.form.isLiked,
      };
      submitComplyRatings(payload).then((resp) => {
        console.log(resp);
      });
    },
    closeFeedback() {
      this.feedbackPopup = false;
    },
    handleRecheck() {
      this.form = {
        isLiked: null,
        feedback: '',
      };
      this.feedbackSuccess = true;
      this.feedbackPopup = false;
      this.handleOnReCheckComply();
    },
    handleOnDivClickFileSelection(event) {
      this.previewFiles(event.target.files);
    },
    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },
    updateLocalFiles(val) {
      this.localFiles = val;
    },
    onCancel() {
      if (this.getComplianceScore) {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]:
            this.selectedTemplate === ''
              ? this.currentUser.theme.code
              : this.selectedTemplate,
          [TD_PLACEMENT]: TD_FINAL_STEP,
        };
        trackComplyEvents.complianceCheckerCancel(this.currentUser, otherData);
      } else {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]:
            this.selectedTemplate === ''
              ? this.currentUser.theme.code
              : this.selectedTemplate,
          [TD_PLACEMENT]: this.inTemplateSelectionStep
            ? TD_TEMPLATE_SELECTION
            : TD_SLIDE_SELECTION,
        };
        trackComplyEvents.complianceCheckerCancel(this.currentUser, otherData);
      }
      this.newFilesToUpload = [];
      this.$emit('onCancelledCta', true);
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/comply/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    async previewFiles(event) {
      // Check file type
      const file = event[0];
      if (!['pptx'].includes(file.name.split('.').pop())) {
        this.fileError = 'userUploadPrezentation.invalidFileFormat';
        return;
      }
      this.slidesThresholdExceeds = false;
      this.fileError = '';
      this.checkingUsage = true;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
          this.checkingUsage = false;
        })
        .catch((error) => {
          this.checkingUsage = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.comply?.isLimitCrossed ||
        this.limitsInfoObj?.comply?.isFullAccess
      ) {
        this.newFilesToUpload = [...event];
        this.newFilesToUpload = this.newFilesToUpload.map((i) => ({
          file: i,
          name: i.name,
          status: 'Processing',
          progress: 0,
          path: `${this.getOutputPath()}/${removeSpecialCharacters(i.name)}`,
          processingCompleted: false,
          requestIdentifier: uuidv4(),
        }));
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'comply' });
      }
      this.handleOnDivClick();
    },
    onComplianceBtnClick() {
      setTimeout(() => {
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }, 50);
      this.form = {
        isLiked: null,
        feedback: '',
      };
      this.feedbackSuccess = true;
      this.feedbackPopup = false;
      this.handleOnFileUpload(this.newFilesToUpload).then((res) => {
        if (res?.slidesThresholdExceeds) {
          this.slidesThresholdExceeds = true;
        }
      });

      const otherData = {
        [TD_PREZNAME]: this.newFilesToUpload[0].name,
      };
      trackComplyEvents.complianceCheckerUploadCheckCompliance(
        this.currentUser,
        otherData,
      );
      const addLog = {
        fileName: this.newFilesToUpload[0].name,
      };
      addComplyLog(addLog)
        .then(() => {
          // if (response?.data) {
          //   this.setLimitData(response.data);
          // }
          // this.checkingUsage = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onComplyUploadDivClick() {
      document.getElementById('hiddenInput').click();
      const otherData = {
        [TD_PLACEMENT]: TD_UPLOAD_SECTION,
      };
      trackComplyEvents.complianceCheckerUpload(this.currentUser, otherData);
    },
    handleOnDivClick() {
      const otherData = {
        [TD_PLACEMENT]: TD_UPLOAD_CTA,
      };
      trackComplyEvents.complianceCheckerUpload(this.currentUser, otherData);
    },
    onChangeTemplate() {
      this.$store.dispatch('comply/openSmartTools', true);
      this.$store.dispatch('comply/setRightPanelComponent', 'compare');
      this.setPlacement('Upload Section');
    },

    getFileName() {
      if (!this.newFilesToUpload.length) return '';
      return this.newFilesToUpload[0].name;
    },
  },
};
</script>
<style scoped lang="scss">
.generate-rating-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-left: 40px;

  .rating-disabled {
    opacity: 0.8;
    cursor: default;
  }
}
.generate-rating-popover {
  border-radius: 16px;

  .feedback-success {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      color: #000;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &__content {
    padding: 22px;
    padding-bottom: 0;
    padding-top: 42px;
  }
  &__close {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
.form {
  &__label {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 16px;
    display: block;
  }
  &__action {
    padding: 22px;
    padding-top: 0;
  }
  &__textarea {
    border-radius: 8px;
  }
}

.bounce {
  animation-name: bounce;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
  transform-origin: bottom;
}
@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-4px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
.comply-upload-section {
  // padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // border-radius: 10px;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  cursor: pointer;
  &__example-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    // font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
    margin-bottom: 5px;
  }
  &__file-name {
    color: #212121;

    /* Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex: 1;
    padding-top: 7px;
    width: calc(100% - 228px);
    overflow: hidden;
  }
  &__right {
    gap: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__upload-icon {
    cursor: pointer;
    padding-top: 0px;
  }
  &__cancel-btn {
    color: #21a7e0;
    /* Field Name */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: normal;
  }
  &__check-compliance-btn {
    display: flex;
    height: 32px;
    align-items: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    color: white;
    letter-spacing: normal;

    /* Elevation/05 */
    // box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    //   0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  &__size-limit-text {
    color: #000;

    /* Small Label */
    font-family: Lato;
    // font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

::v-deep .v-input__icon {
  .v-icon {
    color: #21a7e0 !important;
  }
}
.file-upload-action {
  input[type='file'] {
    display: none;
  }

  .custom-file-upload {
    padding: 6px 16px;
    height: 31px;
    border: 1.5px solid #21a7e0;
    border-radius: 25px;
    color: #21a7e0;
    cursor: pointer;
  }
}

.actions-container {
  display: flex;
  flex-direction: column;
  /* align-items: normal; */
  gap: 10px;
  .re-check {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    > .re-check-cta {
      color: var(--PrezentBlue, #21a7e0);

      /* Field Name */
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: none;
      letter-spacing: normal;
    }
  }
}
.quick-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.blur-effect {
  opacity: 0.4;
  pointer-events: none;
}
</style>
