<template>
  <div class="picker-color-wrapper">
    <div class="back-btn">
      <v-btn @click="handleBack">
        <v-icon size="22">{{ 'mdi-chevron-left' }}</v-icon>
        Back
      </v-btn>
    </div>
    <div class="main-title">Color Suggestion</div>
    <div class="header-wrapper">
      <div>
        <v-avatar
          :max-width="24"
          :max-height="24"
          :min-height="24"
          :min-width="24"
          :color="colorPickerState.suggestion.avatarColor"
          class="mr-2"
        >
          <span class="item-id">{{
            colorPickerState.suggestion.mappingIndex + 1
          }}</span>
        </v-avatar>
        <span class="item-name">{{ colorPickerState.suggestion.name }} </span>
      </div>

      <div class="suggestion-details__actions">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!finalSuggestedColor"
              @click="handleAccept"
              v-bind="attrs"
              v-on="on"
              icon
              data-pendo-id="comply-accept-color-change"
            >
              <v-icon color="#00925a">mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Accept</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!colorPickerState.pickingAfterReview">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!finalSuggestedColor"
              @click="handleReject"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="#d32f2f">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="resetColor"
              color="primary"
              v-bind="attrs"
              v-on="on"
              :disabled="
                colorPickerState.pickingAfterReview &&
                !colorPickerState.selectedColor
              "
            >
              <v-icon>mdi-restore</v-icon>
            </v-btn>
          </template>
          <span>Revert to suggested</span>
        </v-tooltip>
      </div>
    </div>
    <div class="color-fill-suggestion">
      <div class="block">
        <div class="block__wrapper">
          <span class="block__text">{{ suggestionTextLeft }}</span>
          <div class="color-and-details">
            <PreviewShape
              :color="'#' + currentColor"
              :suggestionName="colorPickerState.suggestion.name"
            />
          </div>
        </div>
      </div>
      <div class="arrow">
        <v-icon>mdi-arrow-right</v-icon>
      </div>
      <div
        class="block"
        :style="
          finalSuggestedColor ? {} : { 'margin-bottom': '20px !important' }
        "
      >
        <div class="block__wrapper">
          <span class="block__text">{{ suggestionTextRight }}</span>
          <div class="color-and-details">
            <PreviewShape
              :color="finalSuggestedColor ? `#${finalSuggestedColor}` : ''"
              :suggestionName="colorPickerState.suggestion.name"
            />
          </div>
        </div>
      </div>
      <div
        class="block"
        v-if="
          colorPickerState.selectedColor && !colorPickerState.pickingAfterReview
        "
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="resetColor"
              class="reset-icon"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="28">mdi-restore</v-icon>
            </v-btn>
          </template>
          <span>Revert to suggested</span>
        </v-tooltip>
      </div>
    </div>
    <div class="tints-wrapper">
      <p class="choose-color">Choose new color</p>
      <p class="select-color">Select a color from the brand palette</p>

      <div
        v-for="(color, index) in colorsWithTints"
        :key="origin === 'deck' ? index : color.name + index"
        class="color-row"
      >
        <div class="color-description" v-if="origin === 'deck'">
          <span class="color-name">
            ({{ `#${color.hex.substring(3).toUpperCase()}` }})</span
          >
        </div>
        <div class="color-description" v-else>
          <span class="color-name">{{
            capitalizeFirstLetter(color.name.substring(3))
          }}</span>
          <span class="color-name">
            ({{ `#${color.hex.substring(3).toUpperCase()}` }})</span
          >
        </div>

        <div
          class="color-boxes"
          :data-pendo-id="index == 0 ? 'comply-choose-color' : ''"
        >
          <div
            @click="onColorSelected(`#${color.hex.substring(3)}`)"
            :style="{
              backgroundColor: `#${color.hex.substring(3)}`,
              border: borderStyle(),
              boxShadow: boxShadowStyle,
            }"
            :class="{
              'active-color':
                finalSuggestedColor === `${color.hex.substring(3)}`,
            }"
            class="color-box-main"
          ></div>
          <div
            class="tints-box-wrapper"
            :style="{ border: '1.5px solid #c6c6c6' }"
          >
            <v-tooltip
              bottom
              v-for="(tint, tintIndex) in color.tints"
              :key="tint + tintIndex"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  @click="onColorSelected(tint)"
                  :style="{
                    backgroundColor: tint,
                    boxShadow: boxShadowStyle,
                  }"
                  :class="{
                    'active-color': finalSuggestedColor === tint.substring(1),
                  }"
                  class="tint-box"
                  v-bind="attrs"
                  v-on="on"
                ></div>
              </template>
              <span>
                {{ tint.toUpperCase() }}
              </span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chroma from 'chroma-js';
import { mapActions, mapGetters, mapState } from 'vuex';
import PreviewShape from './PreviewShape.vue';
import { trackComplyEvents } from '../../../../common/Analytics/ComplyEvents';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_COLOR_TRANSITION,
  TD_NUMBEROFSLIDES,
  TD_PLACEMENT,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  props: {
    origin: {
      type: String,
      default: null,
    },
  },
  components: {
    PreviewShape,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', [
      'fileDetails',
      'selectedTemplate',
      'selectedSlides',
    ]),
    ...mapGetters('comply', ['colorPickerState']),
    finalSuggestedColor() {
      const color = this.colorPickerState?.selectedColor
        ? this.colorPickerState?.selectedColor
        : this.colorPickerState.suggestion?.suggested;
      if (color && color.length === 8) {
        return color.substring(2);
      }
      return color;
    },
    currentColor() {
      if (
        this.colorPickerState.suggestion &&
        this.colorPickerState.suggestion.current &&
        this.colorPickerState.suggestion.current.length === 8
      ) {
        console.log('currentColor', this.colorPickerState.suggestion);
        return this.colorPickerState.suggestion.current.substring(2);
      }
      return this.colorPickerState.suggestion.current;
    },
    colorPalette() {
      if (this.origin.toLowerCase() === 'deck') {
        const hexCodeList = [];
        // if (
        //   this.colorPickerState.suggestion?.target_colors &&
        //   this.colorPickerState.suggestion?.target_colors.length &&
        //   !this.colorPickerState?.pickingAfterReview
        // ) {
        //   this.colorPickerState.suggestion.target_colors.forEach((color) =>
        //     hexCodeList.push({ hex: `#${color}` }),
        //   );
        //   return hexCodeList;
        // }
        if (
          this.colorPickerState.suggestion?.color_order_palette &&
          this.colorPickerState.suggestion?.color_order_palette.length
        ) {
          this.colorPickerState.suggestion.color_order_palette.forEach(
            (color) => hexCodeList.push({ hex: `#${color}` }),
          );
          return hexCodeList;
        }
        return this.colorPickerState.suggestion.color_palette;
      }
      return this.colorPickerState.suggestion.color_palette;
    },
    /**
     * Utilizing Chroma.js to generate tints of colors
     * Calculates the luminance of each color to create a
     * tints either by darkening or brightening the base color.
     *
     * - chroma.scale() is used to create a range of colors (tints).
     * - The .darken() and .brighten() methods are utilized based on the color's luminance.
     * - chroma.luminance() helps in determining the brightness of the color to decide on the tinting approach.
     * - An additional shade is added & the lightest one removed to maintain contrast & avoid extremes.
     * - The tints are sorted by luminance to organize them from lighter to darker for consistent presentation.
     *
     * @returns {Array} - Returns an array of objects containing a base color and its tints.
     */
    colorsWithTints() {
      return this.colorPalette.map((color) => {
        const colorFormatted = `#${color.hex.substring(3)}`;
        const brightness = chroma(colorFormatted).luminance();
        let tints = [];

        if (colorFormatted.toUpperCase() === '#FFFFFF') {
          tints = [
            chroma('F2F2F2').hex(),
            chroma('D9D9D9').hex(),
            chroma('BFBFBF').hex(),
            chroma('A6A6A6').hex(),
            chroma('7F7F7F').hex(),
          ];
        } else if (colorFormatted.toUpperCase() === '#000000') {
          tints = chroma.scale(['#111111', 'white']).colors(5);
        } else {
          tints = this.getAdjustedColors(colorFormatted);
        }

        tints = tints.filter((tint) => tint !== colorFormatted);
        if (tints.length < 5) {
          const adjustmentFactor = brightness < 0.2 ? 0.6 : 0.2;
          const newTint =
            brightness > 0.5
              ? chroma(colorFormatted).darken(adjustmentFactor).hex()
              : chroma(colorFormatted).brighten(adjustmentFactor).hex();
          tints.push(newTint);
        }

        tints.sort((a, b) => chroma(b).luminance() - chroma(a).luminance());

        return {
          ...color,
          tints,
        };
      });
    },

    suggestionText() {
      return this.colorPickerState.selectedColor ? 'Selected' : 'Suggested';
    },

    suggestionTextLeft() {
      if (
        (this.colorPickerState?.suggestion?.status &&
          this.colorPickerState?.suggestion?.status.toLowerCase() ===
            'accepted') ||
        this.colorPickerState.pickingAfterReview
      )
        return 'Previous';
      return 'Current';
    },
    suggestionTextRight() {
      if (
        this.colorPickerState.selectedColor ||
        this.colorPickerState.pickingAfterReview
      )
        return 'Selected';
      if (
        this.colorPickerState?.suggestion?.status &&
        this.colorPickerState.suggestion.status.toLowerCase() === 'accepted'
      )
        return 'Current';
      return 'Suggested';
    },
  },
  methods: {
    ...mapActions('comply', ['updateSuggestedColor', 'setColorPickerState']),
    handleBack() {
      this.$emit('close', this.colorPickerState);
    },
    handleAccept() {
      this.$emit('accept', this.colorPickerState);
    },
    handleReject() {
      this.$emit('reject', this.colorPickerState);
    },

    /**
     * This function takes a base color and generates an array of five hex color codes.
     * It creates a gradient from a lighter to a darker version of the base color
     * and selects colors from this gradient based on the base color's luminance.
     *
     * @param {string} colorFormatted - A formatted string representing the base color.
     * @return {Array<string>} An array of five hex color codes.
     */
    getAdjustedColors(colorFormatted) {
      const color = chroma(colorFormatted);
      const brightness = color.luminance();
      const lighter = color.brighten(2);
      const darker = color.darken(2);
      console.log('brightness', brightness);

      const gradient = chroma.scale([lighter, color, darker]).mode('lch');

      if (brightness > 0.8) {
        return [
          gradient(0.25).hex(),
          gradient(0.3).hex(),
          gradient(0.6).hex(),
          gradient(0.7).hex(),
          gradient(0.8).hex(),
        ];
      }

      if (brightness < 0.2) {
        return [
          gradient(0.01).hex(),
          gradient(0.12).hex(),
          gradient(0.23).hex(),
          gradient(0.38).hex(),
          gradient(0.79).hex(),
        ];
      }

      return [
        gradient(0.05).hex(),
        gradient(0.3).hex(),
        gradient(0.55).hex(),
        gradient(0.8).hex(),
        gradient(0.99).hex(),
      ];
    },
    calculateBrightness(luminance) {
      return luminance * 100;
    },
    borderStyle() {
      return `1.5px solid #c6c6c6`;
    },
    boxShadowStyle() {
      return '-1px -1px 2px 0px rgba(0, 0, 0, 0.16), 1px 1px 2px 0px rgba(0, 0, 0, 0.80)';
    },
    onColorSelected(newColor) {
      const color = newColor.slice(1);
      this.setColorPickerState({
        ...this.colorPickerState,
        selectedColor: color,
      });
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_COLOR_TRANSITION,
        [TD_COMMON_COLUMN_VALUE]: `${this.colorPickerState.suggested.slice(
          2,
        )}_${color}`,
        [TD_NUMBEROFSLIDES]:
          this.origin === 'slide' ? 1 : this.selectedSlides.length,
        [TD_PLACEMENT]:
          (this.colorPickerState?.suggestion?.status &&
            this.colorPickerState?.suggestion?.status.toLowerCase() ===
              'accepted') ||
          this.colorPickerState.pickingAfterReview
            ? 'After suggestions are resolved'
            : 'Before suggestions are resolved',
      };
      trackComplyEvents.complianceCheckerColorChanged(
        this.currentUser,
        otherData,
      );
    },
    resetColor() {
      this.setColorPickerState({
        ...this.colorPickerState,
        selectedColor: null,
      });
      if (this.origin === 'deck') {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_COMMON_COLUMN_NAME]: 'Suggestion',
          [TD_COMMON_COLUMN_VALUE]: this.colorPickerState.suggestion.name,
        };
        trackComplyEvents.complianceCheckerSuggestionChangeReset(
          this.currentUser,
          otherData,
        );
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    calculateShadeNameAndPercentage(originalColor, tint) {
      const luminanceOriginal = chroma(originalColor).luminance();
      const luminanceTint = chroma(tint).luminance();

      // const percentage = Math.round(
      //   Math.abs(luminanceOriginal - luminanceTint) * 100,
      // );
      const shadeName =
        luminanceTint > luminanceOriginal ? 'Lighter' : 'Darker';

      return `${shadeName}, ${tint.toUpperCase()}`;
    },
  },
};
</script>
<style scoped lang="scss">
.picker-color-wrapper {
  padding: 0 24px 32px 24px;
  height: 100vh;
  .tints-box-wrapper {
    display: flex;
  }
  .choose-color {
    color: #212121;
    font-size: 20px;
    font-weight: 700;
  }

  .select-color {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    margin-top: 16px;
  }

  .color-row {
    display: flex;
    align-items: center;
    margin-top: 18px;
    justify-content: space-between;
  }

  .color-name {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.14px;
  }

  .color-boxes {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .color-box-main,
  .tint-box,
  .active-color {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .color-box-main {
    margin-right: 12px;
  }
  .active-color {
    box-shadow: -1px -1px 2px 0px rgba(0, 0, 0, 0.16),
      1px 1px 2px 0px rgba(0, 0, 0, 0.8);
  }

  .color-description {
    display: flex;
    gap: 6px;
  }

  .color-fill-suggestion {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 8px 38px 24px 31px;
  }
  .block {
    display: flex;

    &__text {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
    &__wrapper {
      gap: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .color-box {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    font-size: 24px;
  }
  .color-details {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
    margin-top: 6px;
    text-transform: uppercase;
  }
  .arrow {
    color: #191c1e;
    width: 24px;
    height: 24px;
    margin: 24px;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item-id {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .item-name {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
  }

  .main-title {
    color: #212121;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 12px 0 24px 0;
  }
  .reset-icon {
    cursor: pointer;
    border-radius: 100px;
    display: flex;
    align-self: baseline;
    margin-left: -48px;
    margin-top: -10px;
  }

  .back-btn {
    margin: 0px 0px 18px 0px;
    .v-btn {
      box-shadow: none;
      background-color: #ffffff;
      color: #21a7e0;
      letter-spacing: normal;
      text-transform: none;
      padding: 0;
    }
  }

  .tints-wrapper {
    max-height: calc(100vh - 450px);
    overflow-y: auto;
  }
}
</style>
