<template>
  <div class="knowDecksContainer">
    <div class="deckListLoader" v-if="loading">
      <Loading />
    </div>
    <div v-else>
      <template v-if="decksLoadedOnPage.length">
        <v-item-group active-class="primary" class="text-center">
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(prezentation, i) in decksLoadedOnPage"
                :key="`${prezentation.id}__${i}`"
                class="col-xs-12 col-sm-6 col-md-4 col-xs-12 col-lg-4 col-xl-4"
                :style="[
                  $vuetify.breakpoint.xs ||
                  $vuetify.breakpoint.sm ||
                  $vuetify.breakpoint.md
                    ? { 'min-width': '300px' }
                    : {},
                ]"
              >
                <v-item>
                  <KnowDeckCards
                    :prezentation="prezentation"
                    :origin="origin"
                    :isFirstcard="i === 0 ? true : false"
                  />
                </v-item>
              </v-col>
            </v-row>
          </v-container>
        </v-item-group>
        <div class="decks-loadmore">
          <v-btn
            rounded
            outlined
            color="#21a7e0"
            height="32"
            min-width="157px"
            :disabled="decksLoadedOnPage.length >= getDecksLoaded.length"
            @click="handleLoadMoreDecks"
          >
            {{
              `${
                decksLoadedOnPage.length >= getDecksLoaded.length
                  ? $t('bestPracticesGuide.knowContainer.noMoreResults')
                  : $t('bestPracticesGuide.knowContainer.seeMoreResults')
              }`
            }}
          </v-btn>
        </div>
      </template>
      <template v-else>
        <div class="no-decks">
          <img
            src="/assets/img/know/no-results-found-know-decks.svg"
            width="400px"
          />
          <h2>{{ $t('bestPracticesGuide.knowContainer.noResultsFound') }}</h2>
          <p>{{ $t('search.dontLoseHeart') }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EventBus from '../../common/event-bus';
import {
  getFingerPrintAdditionalData,
  getBestPracticesExamplesDecks,
} from '../../../utils/api-helper';
import KnowDeckCards from './KnowDeckCards.vue';
import { trackKnowEvents } from '../../common/Analytics/KnowEvents';
import { TD_PLACEMENT } from '../../common/Analytics/MatomoTrackingDataHelper';
import Loading from '../../common/Loading.vue';

export default {
  name: 'KnowDecks',
  data() {
    return {
      loading: false,
      decksLoadedOnPage: [],
    };
  },
  components: {
    KnowDeckCards,
    Loading,
  },
  props: {
    origin: {
      type: String,
      default: 'landing_page',
    },
    allDecksFromHybridSearch: {
      type: Array,
      default: () => [],
    },
    decksFromHybridSerach: {
      type: Array,
      default: () => [],
    },
    isKnowDecksLoaded: {
      type: Function,
      default: () => {},
    },
    handleSearchChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['isFilterByBPEFunctionEnabled']),
    ...mapGetters('bestPracticesExamples', [
      'getAllDecks',
      'getDecksLoaded',
      'getBPEDeckLandingSearchText',
    ]),

    ...mapGetters('commonDownloads', [
      'getNavFromDownloadSnackbarBPDck',
      'getNavFromAddToLibOrFavSnackbar',
    ]),
  },
  watch: {
    // deep: true,
    // immediate: true,
    getDecksLoaded: {
      handler(val) {
        this.decksLoadedOnPage = val.slice(0, 6);
      },
      // deep: true,
      // immediate: true,

      getNavFromDownloadSnackbarBPDck(val) {
        if (
          val.navigated &&
          this.$route.fullPath === '/home/best-practice-library'
        ) {
          if (val.type === 'bpDecks') {
            this.handleNavFromDownloadSnackbarForDecks();
            this.setNavFromDownloadSnackbarBPDck({
              navigated: false,
              type: '',
            });
          }
        }
      },
    },

    // getNavFromDownloadSnackbarBPDck(val) {
    //   if (
    //     val.navigated &&
    //     this.$route.fullPath === '/home/best-practice-library'
    //   ) {
    //     if (val.type === 'bpDecks') {
    //       this.handleNavFromDownloadSnackbarForDecks();
    //       this.setNavFromDownloadSnackbarBPDck({
    //         navigated: false,
    //         type: '',
    //       });
    //     }
    //   }
    // },

    // getNavFromAddToLibOrFavSnackbar(val) {
    //   if (
    //     val.navigated &&
    //     val.type === 'addToFavPrezBp' &&
    //     val.origin === 'fav_prez_bp' &&
    //     this.$route.fullPath === '/home/best-practice-library'
    //   ) {
    //     this.handleNavFromFavSnackbarForDecks();
    //     this.setNavFromAddToLibOrFavSnackbar({
    //       navigated: false,
    //       type: '',
    //       origin: '',
    //     });
    //   }
    // },
  },
  methods: {
    ...mapActions('bestPracticesExamples', [
      'setDecksOnLoad',
      'setDecksLoaded',
      'setBPEDeckLocalSearchText',
    ]),

    ...mapActions('advancedFilterStore', [
      'setFingerPrintAdditionalDetailsBPEDeckOnload',
      'setFingerPrintAdditionalDetailsBPEDeck',
      'setKnowAdvancedFilterDecks',
      'setKnowDeckIsSearching',
    ]),

    ...mapActions('commonDownloads', [
      'setNavFromDownloadSnackbarBPDck',
      'setNavFromAddToLibOrFavSnackbar',
    ]),

    async getAdvanceFilterFuntions() {
      try {
        const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
        this.setFingerPrintAdditionalDetailsBPEDeck(fingerPrintAdditionalData);
        this.setFingerPrintAdditionalDetailsBPEDeckOnload(
          fingerPrintAdditionalData,
        );
      } catch (err) {
        console.log(err);
      }
    },

    handleLoadMoreDecks() {
      if (this.decksLoadedOnPage?.length < this.getDecksLoaded?.length) {
        const startIndex = 0;
        const endIndex = Math.min(
          this.getDecksLoaded.length,
          this.decksLoadedOnPage.length + 6,
        );
        const loadedSlides = this.getDecksLoaded.slice(startIndex, endIndex);
        this.decksLoadedOnPage = [...loadedSlides];
        trackKnowEvents.knowSeeMoreResults(this.currentUser, {
          [TD_PLACEMENT]: 'Decks',
        });
      }
    },

    handleNavFromFavSnackbarForDecks() {
      const advItems =
        this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      const advancedItemsWithMyDownloads = [...advItems].map((item) => {
        if (item.title === 'Favorites') {
          return {
            ...item,
            checkAll: true,
            items: [
              {
                active: true,
                display_title: 'filters.myFavorites',
                title: 'My Favorites',
              },
            ],
          };
        }
        return item;
      });
      this.setKnowAdvancedFilterDecks(advancedItemsWithMyDownloads);
      EventBus.$emit('KNOW_ADVANCE_FILTER_CHANGE');
    },

    handleNavFromDownloadSnackbarForDecks() {
      const advItems =
        this.$store.state.advancedFilterStore.knowDeck.advancedItems;
      const advancedItemsWithMyDownloads = [...advItems].map((item) => {
        if (item.title === 'Downloads') {
          return {
            ...item,
            checkAll: true,
            items: [
              {
                active: true,
                display_title: 'filters.myDownloads',
                title: 'My Downloads',
              },
            ],
          };
        }
        return item;
      });
      this.setKnowAdvancedFilterDecks(advancedItemsWithMyDownloads);
      EventBus.$emit('KNOW_ADVANCE_FILTER_CHANGE');
    },
  },
  async mounted() {
    if (this.origin && this.origin !== 'hybrid_search') {
      this.loading = true;
      this.setKnowDeckIsSearching(true);
      await getBestPracticesExamplesDecks()
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.items &&
            resp.data.items.length > 0
          ) {
            this.setDecksOnLoad([...resp.data.items]);
            if (this.getBPEDeckLandingSearchText.length > 0) {
              this.handleSearchChange(this.getBPEDeckLandingSearchText);
            }
          } else {
            this.setDecksOnLoad([]);
          }
          // this.isKnowDecksLoaded();
        })
        .catch((err) => {
          this.setDecksOnLoad([]);
          // this.isKnowDecksLoaded();
          console.log(err);
        });
      this.decksLoadedOnPage = this.getDecksLoaded.slice(0, 6);
      this.loading = false;
    } else {
      this.setDecksOnLoad(
        this.allDecksFromHybridSearch &&
          this.allDecksFromHybridSearch.length > 0
          ? [...this.allDecksFromHybridSearch]
          : [],
      );
      this.setDecksLoaded(
        this.decksFromHybridSerach && this.decksFromHybridSerach.length > 0
          ? [...this.decksFromHybridSerach]
          : [],
      );
      // this.isKnowDecksLoaded();
    }

    if (this.isFilterByBPEFunctionEnabled) {
      await this.getAdvanceFilterFuntions();
    }

    // handling scenario where navigation is from snackbar items
    if (this.getNavFromDownloadSnackbarBPDck.navigated) {
      this.handleNavFromDownloadSnackbarForDecks();
      this.setNavFromDownloadSnackbarBPDck({ navigated: false, type: '' });
    }

    if (
      this.getNavFromAddToLibOrFavSnackbar.navigated &&
      this.getNavFromAddToLibOrFavSnackbar.type === 'addToFavPrezBp' &&
      this.getNavFromAddToLibOrFavSnackbar.origin === 'fav_prez_bp'
    ) {
      this.handleNavFromFavSnackbarForDecks();
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: false,
        type: '',
        origin: '',
      });
    }

    this.isKnowDecksLoaded();
  },
  beforeDestroy() {
    if (this.origin !== 'hybrid_search') this.setBPEDeckLocalSearchText('');
  },
};
</script>

<style lang="scss" scoped>
.knowDecksContainer {
  .deckListLoader {
    text-align: center;
    margin: 150px 0px 0px 0px;
  }
  .decks-loadmore {
    display: flex;
    justify-content: center;
    padding: 23px 0px 0px 0px;

    button {
      color: $zen-blue-default;
      font-size: 14px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: normal;
      line-height: normal;
      text-align: center;
      text-transform: none;
    }
  }
  .no-decks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 600px;
    p {
      font-size: 20px;
    }
  }
}
</style>
