<template>
  <div class="multipanel-container">
    <div class="textbox-label">
      Provide additional business context in the form of text, links, or files.
      We will use the text data to generate more relevant slide content.
    </div>
    <v-expansion-panels class="multiContentPanels" v-model="currPanel">
      <template v-for="panel in panels">
        <v-expansion-panel :key="panel.id">
          <v-expansion-panel-header>
            <img :src="panel.icon" alt />
            <div class="panelHeader">{{ panel.description }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="panel.type === 'texts'" class="textContent">
              <div class="textHeading">
                {{
                  'Enter additional information or business context to be considered for generating your slide'
                }}
              </div>
              <v-textarea
                v-model="currVal.texts.text"
                @change="onChange"
                solo
                :placeholder="'Example: Founded in 2005, ACME Corp. stands at the forefront of pharmaceutical innovation, dedicated to advancing healthcare through cutting-edge research and development. We specialize in the discovery and development of novel therapeutics.'"
                :no-resize="true"
              >
                <template #append>
                  <v-btn
                    text
                    class="clear-button"
                    rounded
                    v-if="currVal.texts.text && currVal.texts.text.length > 0"
                    color="#20a7e0"
                    height="30"
                    @click="currVal.texts.text = ''"
                    >{{ $t('common.clear') }}</v-btn
                  >
                </template>
              </v-textarea>
            </div>
            <div v-if="panel.type === 'links'" class="linkContent">
              <div class="linkHeading">
                {{
                  'Add links to any publicly accessible web pages or Google documents. For any private Google document links, please share access with autogenerator@prezent.ai'
                }}
                <p class="link-sub-heading">
                  {{
                    ' Only the content in text format will be used as context.'
                  }}
                </p>
              </div>
              <v-form ref="form">
                <v-textarea
                  v-model="link"
                  @keydown.enter.prevent="onEnter"
                  @paste.prevent="onPaste"
                  class="share-links"
                  solo
                  :rules="linkRules"
                  :placeholder="'Paste links to web pages, Google Slides, or Google Docs.\nAuto Generator works best with files having up to 30 pages.'"
                  :no-resize="true"
                >
                  <template #prepend-inner v-if="currVal.links.length">
                    <div class="links-container">
                      <template v-for="(link, i) in currVal.links">
                        <div class="file-chip" :key="link.web_id">
                          <div class="fileNameWrapper">
                            {{ link.web_url }}
                          </div>

                          <v-icon
                            :size="18"
                            :color="'#7B7B7B'"
                            @click="removeLink(i)"
                            >mdi-close</v-icon
                          >
                        </div>
                      </template>
                    </div>
                  </template>
                  <template #append>
                    <v-btn
                      text
                      class="clear-button"
                      rounded
                      v-if="currVal.links && currVal.links.length > 0"
                      color="#20a7e0"
                      height="30"
                      @click="clearLinks"
                      >{{ $t('common.clear') }}</v-btn
                    >
                  </template>
                </v-textarea>
              </v-form>

              <div class="extract-data-chkbx">
                <v-checkbox
                  v-model="currVal.extract_graph_data"
                  @change="onChange('extract')"
                  :hide-details="true"
                  :label="'Extract data from images of graphs and tables'"
                />
                <p class="sub-text">
                  {{
                    'Requires additional processing and may increase generation time'
                  }}
                </p>
              </div>
              <div
                :class="`${
                  currVal.links.length > 0 && !currVal.linkAccess
                    ? 'check-access-error'
                    : 'check-access'
                }`"
              >
                <v-checkbox
                  v-model="currVal.linkAccess"
                  @change="onChange"
                  :hide-details="true"
                  :label="'All links are publicly accessible / I have shared access with autogenerator@prezent.ai'"
                />
                <span class="guide-link" @click="handleHowtoShareAccess">
                  {{ 'How to share access?' }}
                </span>
              </div>
            </div>
            <div v-if="panel.type === 'files'" class="fileContent">
              <div class="fileHeading">
                {{ 'Select and upload files that contain relevant content' }}
              </div>
              <div class="sizeDesc">
                <span>
                  {{
                    'Auto Generator works best with files having up to 30 pages and under 200MB.'
                  }}
                  <!-- <v-tooltip
                    right
                    max-width="300"
                    content-class="tooltip-content"
                    fixed
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="icon-title"
                        v-text="`mdi-information-outline`"
                        :style="{
                          'font-size': '18px',
                          margin: '0px 0px 4px 2px',
                        }"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <span>
                      {{
                        'Select a .ppt, .pptx, .doc, .docx, .txt or .pdf file.'
                      }}
                    </span>
                  </v-tooltip> -->
                </span>
              </div>
              <div class="file-upload-action">
                <label for="file-upload" class="custom-file-upload">
                  <span>
                    <v-icon>{{ 'mdi-tray-arrow-up' }}</v-icon>
                    {{ 'Upload files' }}
                  </span>
                </label>
                <input
                  id="file-upload"
                  type="file"
                  ref="hiddenInput"
                  :accept="`${allowedUploadTypes.join(', ')}`"
                  @change="previewFiles"
                  multiple
                />
              </div>
              <div class="file-chips-wrapper">
                <div
                  class="file-placeholder"
                  v-if="!currVal.files || currVal.files.length === 0"
                  @click="uploadFileClick"
                >
                  Upload your documents (.pdf or .docx) and presentations
                  (.pptx)
                </div>
                <template v-else>
                  <div class="chips-list">
                    <div
                      class="file-chip"
                      v-for="(lFile, i) in currVal.files"
                      :key="i"
                      :style="{
                        height: `${getFileItemsHeight(lFile)}`,
                      }"
                    >
                      <div class="fileNameWrapper">
                        {{ lFile.fileName ? lFile.fileName : lFile.name }}
                      </div>
                      <v-icon
                        :size="18"
                        :color="'#7B7B7B'"
                        @click="removeFile(i)"
                        >mdi-close</v-icon
                      >
                    </div>
                  </div>
                  <div class="clear-button-cont">
                    <v-btn
                      text
                      class="clear-button"
                      rounded
                      v-if="currVal.files && currVal.files.length > 0"
                      color="#20a7e0"
                      height="30"
                      @click="currVal.files = []"
                      >{{ $t('common.clear') }}</v-btn
                    >
                  </div>
                </template>
              </div>
              <div
                v-if="currVal.files.length > 3"
                class="file-error error--text"
              >
                Please note adding more than three files may increase the slide
                generation time
              </div>

              <div class="extract-data-chkbx">
                <v-checkbox
                  v-model="currVal.extract_graph_data"
                  @change="onChange('extract')"
                  :hide-details="true"
                  :label="'Extract data from images of graphs and tables'"
                />
                <p class="sub-text">
                  {{
                    'Requires additional processing and may increase generation time'
                  }}
                </p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
    <!-- <v-btn
      v-if="!isFirstGeneration"
      elevation="2"
      rounded
      color="primary"
      class="regenrate-btn"
      @click="regenerate"
    >
      {{ 'Regenerate deck' }}
    </v-btn> -->
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import UploadMultiContent from './UploadMultiContent.vue';
import HowToShareAccess from './HowToShareAccess.vue';

export default {
  name: 'MultiContentPanels',
  data() {
    return {
      currVal: {
        texts: { text: '' },
        files: [],
        links: [],
        linkAccess: false,
        extract_graph_data: false,
      },
      link: '',
      panels: [
        {
          type: 'texts',
          id: 1,
          description: 'Text',
          icon: '/assets/img/text-box-edit-outline.svg',
        },
        {
          type: 'links',
          id: 2,
          description: 'Web Link',
          icon: '/assets/img/link-variant.svg',
        },
        {
          type: 'files',
          id: 3,
          description: 'Local Files',
          icon: '/assets/img/laptop.svg',
        },
      ],
      linkRules: [
        (v) =>
          this.isValidHttpUrl(v) ||
          v.length <= 0 ||
          'Oops! One or more of the entered links are invalid',
        () =>
          this.currVal.links.length <= 3 ||
          'Please note adding more than three links may increase the slide generation time',
      ],
      sharedAccess: false,
      allowedUploadTypes: ['.ppt', '.pptx', '.doc', '.docx', '.txt', '.pdf'],
      currPanel: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    openTextPanel: {
      type: Boolean,
    },
  },
  computed: {},
  watch: {
    value(val) {
      console.log(val, 'watcher in multi');
      this.currVal = val;
    },
  },

  methods: {
    clearLinks() {
      this.currVal.links = [];
      this.link = '';
      this.$refs.form[0].validate();
    },

    onChange(type) {
      if (type === 'extract' && !this.currVal.extract_graph_data) {
        this.currVal = {
          ...this.currVal,
          links: this.currVal.links.map((i) => ({
            ...i,
            web_id: uuidv4(),
          })),
          files: this.currVal.files.map((i) => ({
            ...i,
            fileID: uuidv4(),
          })),
        };
      }
      this.$emit('input', { ...this.currVal });
    },
    onPaste(evt) {
      if (this.isValidHttpUrl(evt.clipboardData.getData('text/plain'))) {
        this.currVal.links.push({
          web_id: uuidv4(),
          web_url: evt.clipboardData.getData('text/plain'),
        });
        this.link = '';
        this.currVal.linkAccess = false;
        this.onChange();
      } else {
        this.link = evt.clipboardData.getData('text/plain');
      }
      this.$refs.form[0].validate();
    },
    onEnter() {
      console.log(this.link);
      if (this.isValidHttpUrl(this.link)) {
        this.currVal.links.push({
          web_id: uuidv4(),
          web_url: this.link,
        });
        this.link = '';
        this.currVal.linkAccess = false;
        this.onChange();
        this.$refs.form[0].validate();
      }
    },
    isValidHttpUrl(string) {
      if (!string || string.trim().length === 0) {
        return false;
      }
      const linksArr = string.split('\n').filter((ln) => ln !== '');
      for (let i = 0; i < linksArr.length; i++) {
        let url = linksArr[i];
        try {
          url = new URL(url);
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            console.log(url.protocol);
          } else {
            return false;
          }
        } catch (_) {
          return false;
        }
      }
      return true;
    },

    handleHowtoShareAccess() {
      this.$modal.show(
        HowToShareAccess,
        {},
        {
          name: 'HowToShareAccess',
          classes: 'v-application',
          width: '900px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    // regenerate() {
    //   this.$emit('completed', this.currVal);
    // },

    fileAlreadyUploaded(uploadedFiles, file) {
      return uploadedFiles.find((fl) => fl.fileName === file.name);
    },

    updateLocalFiles(val) {
      const filesToUpload = [...this.currVal.files, ...val];
      this.currVal.files = filesToUpload;
      this.onChange();
    },

    async previewFiles(event) {
      // this.localFiles = [...event.target.files];
      const newFilesToUpload = [...event.target.files].filter(
        (fle) => !this.fileAlreadyUploaded(this.currVal.files, fle),
      );
      if (newFilesToUpload.length > 0) {
        this.$modal.show(
          UploadMultiContent,
          {
            filess: [...newFilesToUpload],
            updateLocalFiles: this.updateLocalFiles,
            size: this.currVal.files.reduce((acc, curr) => acc + curr.size, 0),
          },
          {
            name: 'UploadMultiContent',
            width: '600px',
            height: 'auto',
            styles: {
              'max-height': '45%',
              'max-width': '600px',
              'border-radius': '12px',
              overflow: 'auto',
            },
            clickToClose: false,
          },
        );
        // event.target.value = '';
      }
      event.target.value = '';
    },

    getFileItemsHeight(file) {
      if (file.fileName) {
        if (file.fileName.length <= 40) {
          return '32px';
        }
        return 'auto';
      }
      return file.name.length <= 40 ? '32px' : 'auto';
    },

    uploadFileClick() {
      this.$refs.hiddenInput[0].click();
    },

    removeFile(i) {
      this.currVal.files.splice(i, 1);
      this.onChange();
    },
    removeLink(i) {
      this.currVal.links.splice(i, 1);
      this.onChange();
      this.$refs.form[0].validate();
    },
  },
  mounted() {
    if (this.openTextPanel) {
      this.currPanel = 0;
    }
    console.log(this.value, 'multi mount');
    this.currVal = { ...this.value };
  },
};
</script>

<style lang="scss" scoped>
.multipanel-container {
  padding: 5px;
  margin-top: 10px;
}
.textbox-label {
  font-family: 'Lato';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.links-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.file-chip {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  max-width: 395px;
  align-items: center;
  // height: 32px;
  gap: 8px;
  position: relative;
  background: #ffffff;
  // box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
  //   -1px -1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #71787e !important;
  border-radius: 8px;
  cursor: default;
  color: #41484d !important;
  height: min-content;

  // &:hover {
  //   box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1),
  //     -1px -1px 8px rgba(0, 0, 0, 0.1);
  // }

  .fileNameWrapper {
    max-width: calc(100% - 24px);
    line-break: anywhere;
  }

  i {
    cursor: pointer;
  }
}

.multiContentPanels {
  ::v-deep .v-expansion-panel {
    margin: 12px 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    border-radius: 8px !important;
    border: none;
    &::before {
      box-shadow: none;
    }
    &::after {
      border-color: #ffffff !important;
    }
    .v-expansion-panel-header {
      padding: 10px;
      img {
        width: 40px;
        height: 40px;
        flex: none;
      }
      .panelHeader {
        margin: 0px 0px 0px 6px;
        font-size: 16px;
        font-weight: 600;
        color: #075689;
      }
    }
    .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 0px 12px 12px 12px !important;

        .clear-button {
          position: absolute;
          bottom: 5px;
          left: 0;
          margin-left: 8px;
          padding: 5px;
          font-family: 'Lato';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.24px;
          text-transform: none;
        }

        .v-input.v-textarea {
          .v-input__control {
            .v-input__slot {
              textarea::placeholder {
                color: #757575;
              }
              position: relative;
              padding-bottom: 40px;
              box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
                -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
            }
          }
        }
        .textContent {
          .textHeading {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            line-height: 20px;
          }
          .textLabel {
            margin: 0px 0px 8px 0px;
            font-weight: 600;
            font-size: 16px;
          }
        }
        .linkContent {
          .linkHeading {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            line-height: 20px;
            .link-sub-heading {
              margin-bottom: 0;
            }
          }
          .linkLabel {
            margin: 0px 0px 8px 0px;
            font-weight: 600;
            font-size: 16px;
          }
          .v-messages__message {
            line-height: 14px;
            word-break: normal;
            overflow-wrap: normal;
            word-wrap: normal;
            hyphens: none;
          }
          .share-links {
            .v-input__control {
              .v-input__slot {
                margin: 0;
                textarea::placeholder {
                  color: #757575;
                }
                display: flex;
                flex-direction: column;
                padding-bottom: 40px;
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
                  -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
              }
              .v-text-field__details {
                display: none;
              }
            }
          }
          .extract-data-chkbx {
            margin: 16px 0px;
            .v-input {
              margin: 0px;
              .v-input__control {
                .v-input__slot {
                  align-items: flex-start;
                  .v-input--selection-controls__input {
                    margin-right: 6px;
                    .v-icon {
                      svg {
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }
                }
              }
            }
            .sub-text {
              font-size: 14px;
              padding: 2px 0px 0px 30px;
              color: #757575;
            }
          }
          .check-access {
            .guide-link {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #21a7e0;
              margin-left: 30px;
              margin-top: 4px;
              display: inline-block;
              cursor: pointer;
            }

            .v-input {
              margin-top: 0;
              padding-top: 0;
              .v-input__control {
                .v-input__slot {
                  align-items: flex-start;
                  .v-input--selection-controls__input {
                    margin-right: 6px;
                    .v-icon {
                      svg {
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }
                  .v-label {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130%;
                    color: #000000;
                  }
                }
              }
            }
          }
          .check-access-error {
            .guide-link {
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 19px;
              color: #21a7e0;
              margin-left: 30px;
              margin-top: 4px;
              display: inline-block;
              cursor: pointer;
            }

            .v-input {
              margin-top: 0;
              padding-top: 0;
              .v-input__control {
                .v-input__slot {
                  align-items: flex-start;
                  .v-input--selection-controls__input {
                    margin-right: 6px;
                    .v-icon {
                      svg {
                        width: 20px;
                        height: 20px;
                        color: red;
                      }
                    }
                  }
                  .v-label {
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 130%;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
        .fileContent {
          .fileHeading {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            line-height: 20px;
          }
          .file-error {
            font-size: 12px;
            line-height: 14px;
            margin-top: 8px;
            padding: 0 12px;
          }
          .sizeDesc {
            font-size: 16px;
            margin: 0px 0px 16px 0px;
            line-height: 20px;
          }
          .file-upload-action {
            margin: 24px 0px 20px 0px;
            input[type='file'] {
              display: none;
            }

            .custom-file-upload {
              span {
                padding: 6px 16px;
                height: 31px;
                border: 1.5px solid #21a7e0;
                border-radius: 25px;
                font-size: 16px;
                color: #21a7e0;
                cursor: pointer;
                letter-spacing: normal;
                .v-icon {
                  color: #21a7e0;
                }
              }
            }
          }
          .file-chips-wrapper {
            .file-placeholder {
              font-size: 16px;
              color: #757575;
            }
            position: relative;
            padding: 12px;
            padding-bottom: 40px;
            height: 140px;
            border-radius: 6px;
            background: white;
            // filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
            //   drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
            box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
              -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
            display: flex;
            .chips-list {
              height: 100%;
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              overflow: auto;
              width: 100%;
            }
          }
          .extract-data-chkbx {
            margin: 16px 0px;
            .v-input {
              margin: 0px;
              .v-input__control {
                .v-input__slot {
                  align-items: flex-start;
                  .v-input--selection-controls__input {
                    margin-right: 6px;
                    .v-icon {
                      svg {
                        width: 20px;
                        height: 20px;
                      }
                    }
                  }
                }
              }
            }
            .sub-text {
              font-size: 14px;
              padding: 2px 0px 0px 32px;
              color: #757575;
            }
          }
        }
      }
    }
  }
}
::v-deep .v-input {
  .v-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
  }
}
</style>
