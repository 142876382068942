<template>
  <div class="template-select-wrapper">
    <div class="heading-block">
      <div class="heading-block__main">{{ title }}</div>
      <div class="heading-block__sub">Powered by ASTRID™ AI</div>
      <p
        class="suggest-block__summary-text"
        :style="{
          marginTop: templateRequestFrom === 'Comply' ? '10px' : '5px',
        }"
      >
        Select the template for your prezentation
      </p>
    </div>
    <div class="search-block">
      <v-text-field
        solo
        rounded
        v-model="templateSearch"
        label="Search for templates"
      >
        <template v-slot:append>
          <v-icon
            color="primary"
            @click="templateSearch = ''"
            v-text="templateSearch ? `mdi-close` : `mdi-magnify`"
          />
        </template>
      </v-text-field>
    </div>
    <div class="tmpLists">
      <h3 v-if="sortedRecomendedTemplatesList().length > 0">
        Recommended company templates
      </h3>
      <v-row>
        <v-col
          cols="12"
          v-for="(template, index) in sortedRecomendedTemplatesList()"
          :key="index"
        >
          <v-hover v-slot="{ hover }">
            <div
              :class="{
                'template-block': !isTemplateInitial(template),
                'template-block current-select-bg': isTemplateInitial(template),
                'template-block template-hover': hover,
              }"
              @click="onNewTemplateSelect(template)"
            >
              <div class="title-block">
                <p class="title-block__title">
                  {{ template.name }}
                </p>
                <p
                  class="title-block__current-select"
                  v-if="isTemplateInitial(template)"
                >
                  Current Selection
                </p>
              </div>
              <v-row>
                <v-col cols="6" class="pa-5">
                  <v-img
                    loading="lazy"
                    :lazy-src="'/assets/img/comply/template1.png'"
                    :aspect-ratio="16 / 9"
                    :src="template.thumbnailURL"
                    contain
                    alt
                  />
                </v-col>
                <v-col cols="6" class="pa-5">
                  <v-img
                    loading="lazy"
                    :lazy-src="'/assets/img/comply/template1.png'"
                    :aspect-ratio="16 / 9"
                    :src="
                      template.sampleSlideURL
                        ? template.sampleSlideURL
                        : template.thumbnailURL
                    "
                    alt
                    contain
                  />
                </v-col>
              </v-row>
            </div>
          </v-hover>
        </v-col>
      </v-row>
      <h3 v-if="sortedOtherTemplatesList().length > 0">
        Other company templates
      </h3>
      <v-row>
        <v-col
          cols="12"
          v-for="(template, index) in sortedOtherTemplatesList()"
          :key="index"
          style="position: relative"
        >
          <v-hover v-slot="{ hover }">
            <v-tooltip
              top
              attach
              absolute
              max-width="200"
              :disabled="!template.disabled"
              :content-class="'center-tooltip'"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  :class="{
                    'template-block': !isTemplateInitial(template),
                    'template-block current-select-bg':
                      isTemplateInitial(template),
                    'template-block template-hover': hover,
                    'template-block disabled-template': template.disabled,
                  }"
                  @click="onNewTemplateSelect(template)"
                >
                  <div class="title-block">
                    <p class="title-block__title">
                      {{ template.name }}
                    </p>
                    <p
                      class="title-block__current-select"
                      v-if="isTemplateInitial(template)"
                    >
                      Current Selection
                    </p>
                  </div>
                  <v-row>
                    <v-col cols="6" class="pa-5">
                      <v-img
                        loading="lazy"
                        :lazy-src="'/assets/img/comply/template1.png'"
                        :aspect-ratio="16 / 9"
                        :src="template.thumbnailURL"
                        contain
                        alt
                      />
                    </v-col>
                    <v-col cols="6" class="pa-5">
                      <v-img
                        loading="lazy"
                        :lazy-src="'/assets/img/comply/template1.png'"
                        :aspect-ratio="16 / 9"
                        :src="
                          template.sampleSlide
                            ? template.sampleSlideURL
                            : template.thumbnailURL
                        "
                        alt
                        contain
                      />
                    </v-col>
                  </v-row>
                </div>
              </template>
              <span>{{ $t('common.comingSoon') }}</span>
            </v-tooltip>
          </v-hover>
        </v-col>
      </v-row>
      <div v-if="noResults" class="not-found-container">
        <img
          src="/assets/img/no-results-found-templates.svg"
          height="250"
          width="250"
          alt
        />
        <div class="heading mb-1 mt-6">
          {{ $t('build.step3.noTemplatesFound') }}
        </div>
        <v-btn
          center
          class="primary-button"
          height="35"
          width="160"
          rounded
          filled
          color="primary"
          @click="handleAddMore"
        >
          {{ $t('build.step3.addTemplates') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';
import AddingMoreTemplateDetail from '../../Profile/AddingMoreTemplateDetail.vue';

export default {
  name: 'TemplateSelection',
  props: {
    resetNewSelection: {
      type: Boolean,
      default: false,
    },
    templatesInfo: {
      type: Object,
      default: () => null,
    },
    selectedTemplate: {
      type: String,
      default: '',
    },
    templateRequestFrom: {
      type: String,
      default: 'Comply',
    },
    title: {
      type: String,
      default: 'Template',
    },
  },
  computed: {
    // ...mapState('comply', ['templatesInfo', 'selectedTemplate']),
  },
  data() {
    return {
      templateSearch: '',
      noResults: false,
    };
  },
  watch: {
    templateSearch: {
      handler() {
        this.sortedRecomendedTemplatesList();
        this.sortedOtherTemplatesList();
        this.noResults = !(
          this.sortedRecomendedTemplatesList().length ||
          this.sortedOtherTemplatesList().length
        );
      },
    },
  },
  methods: {
    isTemplateInitial(template) {
      return template.template_internal_name === this.selectedTemplate;
    },

    onNewTemplateSelect(template) {
      if (template.disabled) return;
      this.$emit('selectedTemplate', template);
    },

    sortedRecomendedTemplatesList() {
      let finalArray = [];
      const theme = this.templatesInfo.recommended_templates.find(
        (x) => x.template_internal_name === this.selectedTemplate,
      );
      finalArray = this.templatesInfo.recommended_templates.filter(
        (x) => x.template_internal_name !== this.selectedTemplate,
      );
      // console.log('final array', finalArray);

      if (theme) {
        finalArray.unshift(theme);
      }
      if (this.templateSearch) {
        const filteredArray = finalArray.filter((x) =>
          x.name.toLowerCase().includes(this.templateSearch.toLowerCase()),
        );
        return filteredArray;
      }
      // console.log(finalArray);
      return finalArray;
    },

    sortedOtherTemplatesList() {
      let finalArray = [];
      const theme = this.templatesInfo.other_templates?.find(
        (x) => x.template_internal_name === this.selectedTemplate,
      );
      finalArray = this.templatesInfo.other_templates?.filter(
        (x) => x.template_internal_name !== this.selectedTemplate,
      );
      finalArray = [
        ...finalArray,
        ...(this.templatesInfo.disabled_templates?.map((i) => ({
          ...i,
          disabled: true,
        })) || []),
      ];
      if (theme) {
        finalArray.unshift(theme);
      }
      if (this.templateSearch) {
        const filteredArray = finalArray.filter((x) =>
          x.name.toLowerCase().includes(this.templateSearch.toLowerCase()),
        );
        return filteredArray;
      }
      return finalArray;
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          templateRequestFrom: this.templateRequestFrom,
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
  },
};
</script>
<style scoped lang="scss">
.template-select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .tmpLists {
    height: calc(100vh - 320px);
    overflow-y: auto;
    .row {
      margin: 0 !important;
    }
  }
}

.heading-block {
  &__main {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__sub {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: #697077;
    margin-top: 4px;
  }
}

.suggest-block {
  align-self: stretch;
  &__summary-text {
    color: #212121;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    margin-bottom: 0;
  }
}

.search-block {
  height: 41px;
  flex-shrink: 0;
  align-self: stretch;
  &__search-box {
    border-radius: 25px;
    background: var(--color-styles-old-white, #fff);
  }
}

.center-tooltip {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.template-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;

  &.disabled-template {
    opacity: 0.4;
    cursor: default;

    .title-block__title {
      color: #a2a9b0;
    }

    &:hover {
      background: #f2f4fb;

      .title-block__title {
        color: #697077;
      }
    }
  }
}

.current-select-bg {
  background: var(--new-palette-secondary-secondary-95, #e1f5fb);
  pointer-events: none !important;
}

.new-select-border {
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1),
    -1px -1px 4px 0 rgba(0, 0, 0, 0.1);
}

.title-block {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-top: 12px;
  padding-right: 20px;
  &__title {
    margin-bottom: 0;
    color: var(--color-styles-old-dark, #212121);

    /* Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
  }
  &__current-select {
    margin-bottom: 0;
    color: var(--color-styles-old-grey-01, #7b7b7b);

    /* Field Name */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.template-hover {
  background: rgba(182, 182, 182, 0.21) !important;
}
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
  margin-top: 30px;
  .heading {
    color: #000000;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0px;
    text-align: center;
  }
  button {
    background: #21a7e0;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 15px 0;
    color: white;
    text-transform: none;
  }
}
</style>
