var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"walkthroughs-wrapper"},[(_vm.previousRoute === 'search')?_c('div',[_c('v-btn',{staticClass:"btn-back",on:{"click":_vm.goToSearchLandingPage}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('slides.backToSearch')))])],1)],1):_vm._e(),_c('div',{staticClass:"walkthroughs-title"},[_vm._v(_vm._s(_vm.$t('help.walkthrough')))]),_c('div',{staticClass:"toolbar"},[_c('v-text-field',{staticClass:"item-search-input",attrs:{"solo":"","single-line":"","hide-details":"","placeholder":!!_vm.routeQuery
          ? _vm.$t('help.displayingResult')
          : _vm.$t('help.searchWalkthroughs'),"autocomplete":"off"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":_vm.resetSearch}},[_vm._v(" "+_vm._s(!!_vm.routeQuery || _vm.walkthroughSearch ? 'mdi-close' : 'mdi-magnify')+" ")])]},proxy:true}]),model:{value:(_vm.walkthroughSearch),callback:function ($$v) {_vm.walkthroughSearch=$$v},expression:"walkthroughSearch"}}),_c('div',{staticClass:"status-text"},[_vm._v(" "+_vm._s(_vm.$t('help.statusText', { completed: _vm.completed, totalWalkthroughs: _vm.totalWalkthroughs, }))+" ")])],1),(
      !_vm.isLoading &&
      _vm.walkthroughs &&
      _vm.walkthroughs.length > 0 &&
      _vm.filteredWalkthroughs.length
    )?_c('div',{staticClass:"walkthrough-items-wrapper"},[_c('v-row',{staticStyle:{"width":"100%"}},[_vm._l((_vm.filteredWalkthroughs),function(item,index){return [(_vm.isWalkthroughFeatureEnabled(item.target_id))?_c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"id":item.target_id,"xs":"12","sm":"12","md":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"walkthrough-item",on:{"click":function($event){return _vm.onWalkthroughClick(item)}}},[(item.status === _vm.completedStatusText)?_c('v-icon',{staticClass:"check-mark"},[_vm._v(" mdi-check-circle")]):_vm._e(),_c('div',{staticClass:"walkthrough-item-img"},[_c('img',{attrs:{"src":item.img,"alt":"","width":"366px","height":"265px"}})]),_c('div',{staticClass:"item-info"},[_c('div',{staticClass:"item-info-title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":"320","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"line-clamp",rawName:"v-line-clamp:20",value:(3),expression:"3",arg:"20"}],staticClass:"item-info-desc"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])],1)],1)]):_vm._e()]})],2)],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":68,"width":4,"color":"primary","indeterminate":""}})],1):_vm._e(),(!_vm.isLoading && !_vm.filteredWalkthroughs.length)?_c('v-row',[_c('EmptyState',{attrs:{"img-url":"/assets/img/help/no-results-found-send-support-request.svg","is-btn-visible":true,"buttonName":'help.supportRequest',"handleCTA":_vm.handleOpenSendRequest},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"no-results__wrapper"},[_c('p',{staticClass:"no-results-found"},[_vm._v(" "+_vm._s(_vm.$t('build.step1.storylineFieldNoResult'))+" ")]),_c('p',{staticClass:"no-results__description"},[_vm._v(" "+_vm._s(_vm.$t('help.noResultDescription'))+" ")])])]},proxy:true}],null,false,2236333838)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }