var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tutorials-wrapper"},[(_vm.previousRoute === 'search')?_c('div',[_c('v-btn',{staticClass:"btn-back",on:{"click":_vm.goToSearchLandingPage}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary"}},[_vm._v("mdi-chevron-left")]),_c('span',[_vm._v(_vm._s(_vm.$t('slides.backToSearch')))])],1)],1):_vm._e(),_c('div',{staticClass:"tutorials-header"},[_vm._v(_vm._s(_vm.$t('generate.tutorials')))]),_c('v-text-field',{staticClass:"item-search-input",attrs:{"solo":"","single-line":"","hide-details":"","autocomplete":"off","placeholder":!!_vm.routeQuery
        ? _vm.$t('help.displayResults', {
            count: _vm.searchResults ? _vm.searchResults.length : 0,
          })
        : _vm.$t('help.searchTutorials')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(!!_vm.routeQuery || _vm.tutorialSearch ? "mdi-close" : "mdi-magnify")},on:{"click":_vm.resetSearch}})]},proxy:true}]),model:{value:(_vm.tutorialSearch),callback:function ($$v) {_vm.tutorialSearch=$$v},expression:"tutorialSearch"}}),_c('v-sheet',{staticClass:"pt-4 pb-10",attrs:{"max-width":"100%"}},[_c('v-chip-group',{attrs:{"show-arrows":"","mandatory":"","cols":"1","active-class":"primary"},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},_vm._l((_vm.keywords),function(keyword,index){return _c('v-chip',{key:index,attrs:{"color":"#21a7e0","outlined":""},on:{"click":function (e) { return _vm.onFilterChipClicked(keyword); }}},[_vm._v(" "+_vm._s(_vm.$t(keyword.display_name))+" ")])}),1)],1),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":68,"width":4,"color":"primary","indeterminate":""}})],1):_vm._e(),(
      _vm.unmodifiedTutorialsList &&
      _vm.unmodifiedTutorialsList.length > 0 &&
      _vm.tutorials.length
    )?_c('div',{staticClass:"tutorial-items__wrapper"},[_c('v-row',{staticStyle:{"width":"100%"}},_vm._l((_vm.tutorials),function(item,index){return _c('v-col',{key:index,staticClass:"d-flex child-flex",attrs:{"xs":"12","sm":"12","md":"6","lg":"4","xl":"3"}},[_c('TutorialCard',{attrs:{"tutorials":_vm.tutorials,"item":item,"handleVideoClick":_vm.handleVideoOpen}})],1)}),1)],1):_vm._e(),(!_vm.tutorials.length && !_vm.loading)?_c('v-row',[_c('EmptyState',{attrs:{"img-url":"/assets/img/help/no-results-found-send-support-request.svg","is-btn-visible":true,"buttonName":'help.supportRequest',"handleCTA":_vm.handleOpenSendRequest},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"no-results__wrapper"},[_c('p',{staticClass:"no-results-found"},[_vm._v(" "+_vm._s(_vm.$t('build.step1.storylineFieldNoResult'))+" ")]),_c('p',{staticClass:"no-results__description"},[_vm._v(" "+_vm._s(_vm.$t('help.noResultDescription'))+" ")])])]},proxy:true}],null,false,2236333838)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }