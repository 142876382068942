<template>
  <div class="faq-wrapper">
    <div class="faq-header">{{ $t('overnightPresentations.faq') }}</div>
    <v-text-field
      solo
      v-model="searchKeyword"
      single-line
      hide-details
      class="item-search-input"
      autocomplete="off"
      :placeholder="
        !!routeQuery ? $t('help.displayingResult') : $t('help.searchFaq')
      "
      :disabled="!!routeQuery"
    >
      <template v-slot:append>
        <v-icon color="primary" @click="resetSearch">
          {{ !!routeQuery || searchKeyword ? `mdi-close` : `mdi-magnify` }}
        </v-icon>
      </template>
    </v-text-field>
    <div v-if="isLoading" class="d-flex justify-center pt-4">
      <LoadSpinner />
    </div>
    <div v-else class="faqs-content">
      <v-row v-if="isShowFAQTypes" class="category-wrapper" no-gutters>
        <template v-for="(item, index) in faqCategories">
          <v-col
            v-if="isFaqFeatureEnabled(item.category)"
            :key="index"
            sm="6"
            md="4"
            lg="3"
            xl="2"
          >
            <div class="category-item" @click="handleClickType(item)">
              <FAQCategoryIcon
                :icon="item.icon"
                :category="item.category"
                :iconUrl="item.iconUrl"
              />
              <span class="category-item__label">{{ item.category }}</span>
            </div>
          </v-col>
        </template>
      </v-row>
      <FAQSearchResult
        v-if="isShowSearchResult && modifiedSearchData.length > 0"
        :search-data="modifiedSearchData"
        :total="searchResults.length"
        @back="resetSearch"
      />
      <v-row v-if="isEmptyState && modifiedSearchData.length < 1">
        <EmptyState
          img-url="/assets/img/help/no-results-found-send-support-request.svg"
          :is-btn-visible="true"
          :buttonName="'help.supportRequest'"
          :handleCTA="handleOpenSendRequest"
        >
          <template v-slot:description>
            <div class="no-results__wrapper">
              <p class="no-results-found">
                {{ $t('build.step1.storylineFieldNoResult') }}
              </p>
              <p class="no-results__description">
                {{ $t('help.noResultDescription') }}
              </p>
            </div>
          </template>
        </EmptyState>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import HelpApi from '@/API/help-api';
import { getFileURL } from '@/utils/calyrex';
import { transformId } from '@/utils/common';
import LoadSpinner from '@/components/common/LoadSpinner';
import EmptyState from '../../../common/EmptyState.vue';
import FAQSearchResult from './FAQSearchResult.vue';
import FAQCategoryIcon from './FAQCategoryIcon.vue';
import EventBus from '../../../common/event-bus';
import { MatomoAnalyticsHandler } from '../../../common/Analytics/MatomoAnalyticsHandler';
import { logSearchQuery } from '../../../../utils/api-helper';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';
import {
  TD_PLACEMENT,
  TD_FAQCATEGORY,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'FAQs',
  components: { LoadSpinner, EmptyState, FAQSearchResult, FAQCategoryIcon },
  mixins: [HelpApi],
  data() {
    return {
      isLoading: true,
      isShowDetail: false,
      searchKeyword: '',
      searchResults: [],
      faqs: [],
      faqCategories: [],
      featureEnablementConfig: {
        'Best Practice Library': 'isKnowEnabled',
        'Overnight Prezentations': 'hasOPAccess',
        'Auto Generator': 'getGenerateAccessLevel',
        'Slide Uploader': 'getUploadAccessLevel',
        'Template Converter': 'isComplyFullAccess',
        Synthesis: 'isSynthesisFullAccess',
        Redesign: 'isRedesignFullAccess',
        Accelerators: 'isAcceleratorsEnabled',
      },
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'isKnowEnabled',
      'getOPAccessLevel',
      'getUploadAccessLevel',
      'getGenerateAccessLevel',
      'isComplyFullAccess',
      'isSynthesisFullAccess',
      'isRedesignFullAccess',
      'isAcceleratorsEnabled',
    ]),
    routeQuery() {
      return this.$route.query.id;
    },
    hasOPAccess() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    isShowFAQTypes() {
      return !this.isShowDetail && this.faqCategories.length;
    },
    isShowSearchResult() {
      return this.isShowDetail && this.searchResults.length;
    },
    isEmptyState() {
      return this.isShowDetail && this.searchResults.length === 0;
    },
    modifiedSearchData() {
      const faqs = [];
      const faqsByCategory = _.groupBy(this.searchResults, 'category');

      Object.keys(faqsByCategory).forEach((category) => {
        if (this.isFaqFeatureEnabled(category)) {
          const faq = {
            category,
            icon: faqsByCategory[category][0]?.icon,
            data: faqsByCategory[category],
          };
          faqs.push(faq);
        }
      });
      return faqs;
    },
    isComplyEnabledWithAccess() {
      const complyFeature = this.currentUser?.features?.find(
        (itm) => itm.feature_name === 'comply',
      );
      return (
        (complyFeature &&
          complyFeature.enabled &&
          complyFeature.access_level &&
          complyFeature.access_level === 'full_access') ||
        (complyFeature &&
          complyFeature.access_level &&
          (complyFeature.access_level === 'limited_access' ||
            complyFeature.access_level === 'restricted_access'))
      );
    },
    checkAstridAccess() {
      const featuresToCheck = [
        'slide-generate',
        'user-prezentation-upload',
        'synthesis',
        'comply',
        'redesign',
      ];
      const features = this.currentUser?.features;
      return features.some(
        (feature) =>
          featuresToCheck.includes(feature.feature_name) &&
          feature.enabled &&
          (feature.access_level === 'full_access' ||
            feature.access_level === 'restricted_access'),
      );
    },
  },
  async mounted() {
    await this.loadData();
    MatomoAnalyticsHandler.trackHelp(
      {
        type: 'faqs',
      },
      this.currentUser,
    );

    if (this.routeQuery) {
      this.handleHybridSearch();
    }

    EventBus.$on('faqs_click', this.resetSearch);
    // console.log(`FAQ : ${JSON.stringify(this.faqCategories)}`);
    if (this.$route.query.category) {
      this.isShowDetail = true;
      this.searchResults = this.faqs.filter(
        (faq) => faq.category === this.$route.query.category,
      );
    }
  },
  beforeDestroy() {
    EventBus.$off('faqs_click', this.resetSearch);
  },
  methods: {
    handleHybridSearch() {
      const logPayload = {
        type: 'Help',
        searchTerm: '',
        searchTermResult: false,
      };
      logPayload.searchTerm = this.$route.query.display_name
        ? this.$route.query.display_name.toLowerCase()
        : '';
      // we transform the ID because Calyrex returns Ids with _ rather than -
      const foundItem = this.faqs.find(
        (e) => e.uuid.toLowerCase() === transformId(this.routeQuery),
      );
      if (foundItem) {
        this.isShowDetail = true;
        this.searchResults = [
          {
            ...foundItem,
            data: [foundItem],
          },
        ];
      }
      logPayload.searchTermResult = !!this.searchResults.length;
      if (this.$route.query.log_search) {
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }
    },
    handleOpenSendRequest() {
      this.$root.$emit('openHelpMenuRequest');
    },
    async loadData() {
      const { data } = await HelpApi.methods.getHelpData('faqs');

      // initialize faqs and categories out of api response
      this.faqs = data || [];
      if (!this.isComplyEnabledWithAccess) {
        this.faqs = [...this.faqs].filter((fq) => fq.category !== 'Comply');
      }
      this.faqCategories = _.uniqBy(this.faqs, 'category');

      // getting icons for all categories while first mount
      const iconsPromises = this.faqCategories.map(async (cat) => {
        const url = await getFileURL(
          this.currentUser.user.cognitoID,
          cat.icon,
          this.currentUser.userIp,
        );
        return { ...cat, iconUrl: url };
      });
      const categoriesWithIcons = await Promise.all(iconsPromises);
      this.faqCategories = [...categoriesWithIcons];

      // updating faqs with iconUrl so that search data too has the correct icon
      let categoryIconsMap = {};
      this.faqCategories.forEach((cat) => {
        categoryIconsMap = {
          ...categoryIconsMap,
          [cat.category]: cat.iconUrl,
        };
      });
      this.faqs = [...this.faqs].map((faq) => ({
        ...faq,
        iconUrl: categoryIconsMap[faq.category],
      }));

      if (!this.checkAstridAccess) {
        const indexToDelete = this.faqCategories.findIndex(
          (item) =>
            item.category === 'ASTRID™ AI' || item.category === 'ASTRID',
        );
        if (indexToDelete > -1) {
          this.faqCategories.splice(indexToDelete, 1);
        }
      }
      this.isLoading = false;
    },
    handleClickType(item) {
      MatomoAnalyticsHandler.trackHelp(
        {
          type: 'faqs',
          actionValue: item.category,
        },
        this.currentUser,
      );
      trackHelpEvents.helpSectionAssetClick(this.currentUser, {
        [TD_FAQCATEGORY]: item.category,
        [TD_PLACEMENT]: 'FAQ',
      });
      this.isShowDetail = true;
      this.searchResults = this.faqs.filter(
        (faq) => faq.category === item.category,
      );
    },
    handleSearch: debounce(
      // eslint-disable-next-line func-names
      function () {
        if (!this.searchKeyword) {
          this.resetSearch();
          return;
        }

        this.isShowDetail = true;
        this.searchResults = this.faqs.filter((item) =>
          `${item.question.toLowerCase()} ${item.answer.toLowerCase()}`.includes(
            this.searchKeyword.toLowerCase(),
          ),
        );
        trackHelpEvents.hybridSearch(
          this.currentUser,
          this.searchKeyword,
          'faqs',
          this.searchResults?.length,
        );
      },
      500,
    ),
    resetSearch() {
      this.searchKeyword = '';
      this.isShowDetail = false;
      this.searchResults = [];
      const query = { ...this.$route.query };
      // removing log_search and display_name from query
      if (query.log_search) {
        delete query.log_search;
      }
      if (query.display_name) {
        delete query.display_name;
      }
      if (this.$route.query.id) {
        this.$router.replace({ query: null });
      }
    },
    isFaqFeatureEnabled(targetId) {
      if (this.featureEnablementConfig[targetId]) {
        const prop = this.featureEnablementConfig[targetId];
        return this[prop];
      }
      return true;
    },
  },
  watch: {
    searchKeyword() {
      this.handleSearch();
    },
    routeQuery(val) {
      if (val) {
        this.handleHybridSearch();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss';
.faq-wrapper {
  font-family: 'Lato';
  font-style: normal;
  padding: 1.5rem 2rem 0 2rem;

  ::v-deep {
    .item-search-input {
      align-items: center;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      justify-content: flex-start;
      margin-left: 2px;
      padding: 0 !important;
      position: relative;
      max-width: 425px;
      z-index: 3;

      > .v-input__control {
        min-height: 40px;
        > .v-input__slot {
          box-shadow: none;
        }
      }

      .v-input__control > .v-input__slot:before {
        border-style: none;
      }

      input {
        background-clip: padding-box;
        border: none;
        color: $gray-search-input;
        display: block;
        font-size: 14px;
        line-height: 1.25;
        margin: 0 auto;
        outline: none;
        padding: 0.5rem 0.7rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        width: 100%;
      }

      input::placeholder {
        color: $gray-dark-login-text !important;
        font-size: 14px;
        opacity: 1;
      }

      .v-input__append-inner {
        padding-right: 5px;
      }
    }
  }

  .faq-header {
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 32px;
    text-align: left;
  }

  .faqs-content {
    padding-left: 5px;

    .category-wrapper {
      margin-left: -2rem;
      max-height: 70vh;
      overflow-y: auto;
      padding-left: 2rem;

      .category-item {
        align-items: center;
        border-radius: 8px;
        box-shadow: $element-box-shadow-default;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 147px;
        justify-content: center;
        margin-bottom: 32px;
        margin-top: 32px;
        overflow: hidden;
        padding: 24px 17px;
        width: 219px;

        &:hover {
          box-shadow: $element-box-shadow-hover;
        }

        &__label {
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
          margin-top: 8px;
          text-align: center;
          letter-spacing: -0.01em;
        }
      }
    }

    .no-results__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      .no-results-found {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -0.01em;
        line-height: 29px;
        text-align: center;
      }
      .no-results__description {
        height: 52px;
        line-height: 130%;
        margin-bottom: -10px !important;
        text-align: center;
        width: 344px;
      }
    }
  }

  ::v-deep {
    .v-expansion-panel--active
      > .v-expansion-panel-header--active
      .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
      .v-icon {
      color: $zen-blue-default;
    }
    .v-expansion-panels {
      display: block;
    }
    .v-input--is-disabled:not(.v-input--is-readonly) {
      pointer-events: all;
    }
  }
}
</style>
