<template>
  <div class="magic-fill-container">
    <div class="heading">
      {{ $t('generate.addContextHeading') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <MultiContentPanels
      v-if="isFirstGeneration"
      :isFirstGeneration="isFirstGeneration"
      :value="getDeckContext"
      :openTextPanel="true"
      @input="deckContextChange"
      class="mcf-container"
    />
    <div class="addContextTabs" v-else>
      <v-tabs
        color="#21a7e0"
        height="57"
        fixed-tabs
        :hide-slider="true"
        v-model="currentTab"
      >
        <v-tab
          :disabled="isTitleOrThankYouSlide"
          :hide-slider="true"
          :style="
            currentTab === 0 ? { 'border-bottom': '2px solid #21a7e0' } : {}
          "
          class="vtab"
          :name--auto="`auto-gen-add-context-tab-current-slide${
            currentTab === 0 ? '--active' : ''
          }`"
        >
          {{ 'Current Slide' }}
        </v-tab>
        <v-tab
          :hide-slider="true"
          :style="
            currentTab === 1 ? { 'border-bottom': '2px solid #21a7e0' } : {}
          "
          class="vtab"
          :name--auto="`auto-gen-add-context-tab-deck${
            currentTab === 1 ? '--active' : ''
          }`"
        >
          {{ 'Deck' }}
        </v-tab>
        <v-tabs-items v-model="currentTab">
          <v-tab-item :value="0">
            <div class="instructions-panel">
              <div class="instructions-heading">
                Provide specific instructions on how you would like this slide
                to be regenerated. ASTRID<sup>TM</sup> will tailor the design
                and content to suit your story.
                <span class="error--text">(required)</span>
              </div>
              <v-textarea
                v-model="slideContext.instructions"
                solo
                :placeholder="'Example 1: Ensure the text is concise and to the point\nExample 2: Summarize the attached documents\nExample 3: Add a point on diversity and inclusion from the provided link'"
                :no-resize="true"
                :rules="instructionRules"
              >
                <template #append>
                  <v-btn
                    text
                    class="clear-button"
                    rounded
                    v-if="
                      slideContext.instructions &&
                      slideContext.instructions.length > 0
                    "
                    color="#20a7e0"
                    height="30"
                    @click="slideContext.instructions = ''"
                    >{{ $t('common.clear') }}</v-btn
                  >
                </template>
              </v-textarea>
            </div>
            <MultiContentPanels
              style="margin-top: 0; padding-top: 0"
              :isFirstGeneration="isFirstGeneration"
              v-model="slideContext"
              :key="currentTab"
            />
          </v-tab-item>
          <v-tab-item :value="1">
            <MultiContentPanels
              :isFirstGeneration="isFirstGeneration"
              :openTextPanel="true"
              v-model="deckContext"
              :key="currentTab"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <div class="action-container">
        <v-btn
          :disabled="disableBtn"
          elevation="2"
          rounded
          color="primary"
          class="regenerate-btn"
          @click="reGenerate"
        >
          {{ currentTab === 0 ? 'Regenerate slide' : 'Regenerate deck' }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEqual } from 'lodash';
import MultiContentPanels from './magic-fill/MultiContentPanels.vue';

export default {
  name: 'MagicFill',
  components: {
    MultiContentPanels,
  },
  data() {
    return {
      applyTitle: false,
      applyContent: true,
      prompt: '',
      currentTab: 0,
      instructionRules: [
        (v) => v.length < 200 || 'Maximum character limit exceeded',
      ],
      deckContext: {
        texts: { text: '' },
        files: [],
        links: [],
        linkAccess: false,
        extract_graph_data: false,
      },
      slideContext: {
        texts: { text: '' },
        files: [],
        links: [],
        instructions: '',
        linkAccess: false,
        extract_graph_data: false,
      },
    };
  },
  props: {
    selectedSlide: {
      type: Object,
      default: () => null,
    },
  },
  watch: {
    selectedSlide: {
      handler(val) {
        if (this.isTitleOrThankYouSlide) {
          this.currentTab = 1;
        } else {
          this.currentTab = 0;
        }
        this.slideContext = JSON.parse(JSON.stringify(val.context));
      },
      deep: true,
    },
    getDeckContext(val) {
      this.deckContext = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters('generateSlides', ['getDeckContext']),
    isFirstGeneration() {
      return !this.selectedSlide;
    },
    valueNotChanged() {
      if (
        this.currentTab === 0 &&
        this.slideContext.instructions ===
          this.selectedSlide.context.instructions &&
        this.slideContext.texts.text ===
          this.selectedSlide.context.texts.text &&
        isEqual(this.slideContext.links, this.selectedSlide.context.links) &&
        isEqual(this.selectedSlide.context.files, this.slideContext.files) &&
        this.slideContext.extract_graph_data ===
          this.selectedSlide.context.extract_graph_data
      ) {
        return true;
      }
      if (
        this.currentTab === 1 &&
        this.deckContext.texts.text === this.getDeckContext.texts.text &&
        isEqual(this.deckContext.links, this.getDeckContext.links) &&
        isEqual(this.getDeckContext.files, this.deckContext.files) &&
        this.deckContext.extract_graph_data ===
          this.getDeckContext.extract_graph_data
      ) {
        return true;
      }
      return false;
    },
    disableBtn() {
      if (
        this.currentTab === 0 &&
        (!this.slideContext.instructions.length ||
          this.slideContext.instructions.length > 200 ||
          (this.slideContext.links.length && !this.slideContext.linkAccess) ||
          this.valueNotChanged)
      ) {
        return true;
      }
      if (
        (this.currentTab === 1 &&
          this.deckContext.links.length &&
          !this.deckContext.linkAccess) ||
        this.valueNotChanged
      ) {
        return true;
      }
      return false;
    },
    isTitleOrThankYouSlide() {
      return (
        this.selectedSlide &&
        !this.selectedSlide.processing &&
        this.selectedSlide.completed &&
        this.selectedSlide.slide_type &&
        (this.selectedSlide.slide_type === 'cover_slide' ||
          this.selectedSlide.slide_type === 'thank_you' ||
          this.selectedSlide.slide_type === 'discussion_topic')
      );
    },
  },
  methods: {
    ...mapActions('generateSlides', ['setDeckContext']),
    reGenerate() {
      if (this.currentTab === 1) {
        this.setDeckContext(this.deckContext);
        this.$emit('change', {
          type: 'context_change',
          subType: 'deck',
          context: JSON.parse(JSON.stringify(this.slideContext)),
        });
      } else {
        this.$emit('change', {
          type: 'context_change',
          subType: 'slide',
          context: JSON.parse(JSON.stringify(this.slideContext)),
        });
      }
    },
    deckContextChange(val) {
      console.log(val, 'deck change');
      this.setDeckContext(JSON.parse(JSON.stringify(val)));
    },
  },
  mounted() {
    if (this.isTitleOrThankYouSlide) {
      this.currentTab = 1;
    }
    this.deckContext = JSON.parse(JSON.stringify(this.getDeckContext));
    this.slideContext = this.selectedSlide
      ? JSON.parse(JSON.stringify(this.selectedSlide.context))
      : {
          text: { text: '' },
          files: [],
          links: [],
        };
  },
};
</script>

<style scoped lang="scss">
.magic-fill-container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .mcf-container {
    flex: 1;
    padding-right: 10px;
    overflow: auto;
  }

  .clear-button {
    position: absolute;
    bottom: 5px;
    left: 0;
    margin-left: 8px;
    padding: 5px;
    font-family: 'Lato';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: none;
  }

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    color: #000000;
  }

  .prompt-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #212121;
    margin-bottom: 36px;
  }

  .textbox-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 16px;
  }
  .addContextTabs {
    min-width: 455px;
    width: 100%;
    height: 96%;
    .v-tab {
      text-decoration: none;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      text-transform: none;
    }
    ::v-deep .v-tabs {
      height: calc(100% - 48px);
      display: flex;
      flex-direction: column;

      .v-item-group {
        height: calc(100% - 57px);
        overflow: auto;
        padding-left: 1px;
        padding-right: 5px;
      }
      .textarea-prompt-container {
        position: relative;
        .clear-button-cont {
          height: 40px;
          width: 98%;
          background-color: white;
          border-radius: 0px 0px 16px 16px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          z-index: 3;
          .clear-button {
            text-transform: none;
          }
        }
        .textarea-prompt {
          width: 100%;
          .v-item-group {
            width: 100%;
            .v-slide-group__wrapper {
              width: 100;
              .v-slide-group__content {
                transform: none !important;
                width: 100%;
                .v-tabs-slider-wrapper {
                  display: none !important;
                }
                .v-tab {
                  width: 50%;
                  letter-spacing: normal;
                  text-transform: none;
                  font-size: 16px;
                }
              }
            }
            .v-slide-group__prev,
            .v-slide-group__next {
              display: none !important;
            }
          }
          .v-window {
            padding: 24px 2px 0px 2px;
            .textarea-prompt-container {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
              border-radius: 8px;
              padding: 12px;
              .iconHeader {
                display: flex;
                margin: 0px 0px 16px 0px;
                img {
                  width: 40px;
                  height: 40px;
                }
                .iconText {
                  font-size: 16px;
                  font-weight: 600;
                  color: #075689;
                  padding: 8px 0px 0px 6px;
                }
              }
              .textHeadingCurrSlide {
                font-size: 16px;
                margin: 0px 0px 16px 0px;
                line-height: 20px;
              }
              .textLabelCurrSlide {
                margin: 0px 0px 8px 0px;
                font-weight: 600;
                font-size: 16px;
              }
              .v-input {
                .v-input__control {
                  .v-input__slot {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 4px;
                    .v-text-field__slot {
                      textarea {
                        resize: none;
                      }
                    }
                  }
                }
              }
              // position: relative;
              // padding: 24px 4px 0px 4px;
              // .clear-button {
              //   position: absolute;
              //   bottom: 10px;
              //   left: 0px;
              //   z-index: 1000;
              //   text-transform: none;
              // }
              // .textarea-prompt {
              //   width: 100%;
              //   border-radius: 16px;
              //   ::v-deep .v-input__slot {
              //     filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.05))
              //       drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.05));
              //     box-shadow: none;

              //     textarea {
              //       height: 201px;
              //     }
              //   }
              // }
            }
            .regenrate-btn {
              margin-top: 25px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 6px 16px;
              gap: 8px;
              height: 32px;
              font-family: 'Lato';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              text-transform: capitalize;
              letter-spacing: normal;
              line-height: 19px;

              /* Color Styles (Old)/White */

              color: #ffffff;

              background: #21a7e0;
              border-radius: 25px;
            }
            // .checkbox-container {
            //   display: flex;
            //   gap: 35px;
            // }
          }
        }
      }
    }
  }

  .vtab {
    font-weight: 300;
    font-size: 1.1rem;
    letter-spacing: normal;
    &:hover {
      text-decoration: none;
    }
  }

  .action-container {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 550px;
    z-index: 10;
    background: white;
    padding: 10px 24px;
  }
  .instructions-panel {
    margin: 10px 0 0 0;
    padding: 5px;
    padding-bottom: 0;

    .instructions-heading {
      margin-bottom: 16px;
      font-family: 'Lato';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    ::v-deep .v-input {
      .v-input__control {
        .v-input__slot {
          textarea::placeholder {
            color: #757575;
          }
          padding-bottom: 40px;
          box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
            -2px -2px 4px 0px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .regenerate-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;

    width: auto;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: none;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }
}
</style>
